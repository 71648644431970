<template>
  <div>
    <online-warning></online-warning>
    <in-page-menu />
    <!-- Move Location -->
    <v-dialog
      :overlay="false"
      max-width="400px"
      scrollable
      transition="dialog-transition"
      v-model="dialogs.move.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>{{ getTranslation("move") }}</h4>
          <v-icon class="buttons" @click="dialogs.move.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-0" style="height: 250px">
          <v-select
            :items="dialogs.move.locationItems"
            :label="getTranslation('locationList')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="mb-4 pt-1 custom-field"
            clearable
            dense
            hide-details
            item-text="name"
            item-value="id"
            outlined
            return-object
            v-model="dialogs.move.form.location"
          ></v-select>
          <v-text-field
            :label="getTranslation('group')"
            class="mb-4 custom-field"
            clearable
            dense
            hide-details
            outlined
            v-model="dialogs.move.form.group"
          ></v-text-field>
          <v-text-field
            :label="getTranslation('reason')"
            class="mb-4 custom-field"
            clearable
            dense
            hide-details
            outlined
            v-model="dialogs.move.form.reason"
          ></v-text-field>
          <v-select
            :items="statusItems"
            :label="getTranslation('statusList')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="mb-4 custom-field"
            clearable
            dense
            hide-details
            item-text="name"
            item-value="id"
            outlined
            return-object
            v-model="dialogs.move.form.status"
          ></v-select>
          <v-menu
            :close-on-content-click="false"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="dialogs.move.showCalendar"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :label="getTranslation('dateRecorded')"
                append-icon="mdi-calendar"
                class="mb-2 custom-field"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                v-model="dialogs.move.form.date"
                v-on:click:append="dialogs.move.showCalendar = true"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              @input="dialogs.move.showCalendar = false"
              v-model="dialogs.move.form.date"
            ></v-date-picker>
          </v-menu>
          <v-checkbox
            class="pb-1 pt-0 mt-0"
            hide-details
            v-model="dialogs.move.form.includeGeopoint"
            v-if="!!healthTrackerReport && !!healthTrackerReport.coords()"
          >
            <template #label>
              <h6 class="mt-4">
                Include GEO Point
                {{ healthTrackerReport.coords().latitude }},{{
                  healthTrackerReport.coords().longitude
                }}
              </h6>
            </template>
          </v-checkbox>
          <v-checkbox
            :value="false"
            class="pb-1 pt-0 mt-0"
            disabled
            hide-details
            v-else
          >
            <template #label>
              <h6 class="mt-4">Include GEO Point</h6>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-divider class="mt-0"></v-divider>
        <v-card-actions class="pt-0 d-flex justify-center">
          <v-btn
            :disabled="!dialogs.move.form.location"
            @click="saveSelectedLocationForAnimals"
            class="buttons white--text"
            color="#3465a1"
            >{{ getLabelTranslation("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Receiving Ranch -->
    <v-dialog
      :overlay="false"
      max-width="400px"
      scrollable
      transition="dialog-transition"
      v-model="dialogs.receivingRanch.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4 class="mb-0">{{ getTranslation("Receiving Ranch") }}</h4>
          <v-icon class="buttons" @click="dialogs.receivingRanch.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text class="py-0" style="height: 252px">
          <v-select
            :items="getOrganizationItems"
            :label="getTranslation('Receiving Ranch')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="pt-1 mb-4 custom-field"
            clearable
            dense
            hide-details
            item-text="name"
            outlined
            return-object
            style="font-size: 1.3rem"
            v-model="dialogs.receivingRanch.form.receivingRanch"
          >
          </v-select>
          <v-text-field
            :label="getTranslation('Group Number')"
            class="mb-4 custom-field"
            clearable
            dense
            hide-details
            outlined
            v-model="dialogs.receivingRanch.form.groupNumber"
          ></v-text-field>
          <v-select
            :items="statusItems"
            :label="getTranslation('statusList')"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            class="mb-4 custom-field"
            clearable
            dense
            hide-details
            item-text="name"
            item-value="id"
            outlined
            return-object
            v-model="dialogs.receivingRanch.form.status"
          ></v-select>
          <v-menu
            :close-on-content-click="false"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="dialogs.receivingRanch.showCalendar"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :label="getTranslation('dateRecorded')"
                append-icon="mdi-calendar"
                class="mb-4 custom-field"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                v-model="dialogs.receivingRanch.form.date"
                v-on:click:append="dialogs.receivingRanch.showCalendar = true"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              @input="dialogs.receivingRanch.showCalendar = false"
              v-model="dialogs.receivingRanch.form.date"
            ></v-date-picker>
          </v-menu>
          <v-checkbox
            class="pb-1 pt-0 mt-0"
            hide-details
            v-model="dialogs.receivingRanch.form.includeGeopoint"
            v-if="healthTrackerReport && !!healthTrackerReport.coords()"
          >
            <template #label>
              <h6 class="mt-4">
                Include GEO Point
                {{ healthTrackerReport.coords().latitude }},{{
                  healthTrackerReport.coords().longitude
                }}
              </h6>
            </template>
          </v-checkbox>
          <v-checkbox
            class="pb-1 pt-0 mt-0"
            disabled
            hide-details
            :value="false"
            v-else
          >
            <template #label>
              <h6 class="mt-4">Include GEO Point</h6>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-divider class="mt-0"></v-divider>
        <v-card-actions class="pt-0 d-flex justify-center">
          <v-btn
            :disabled="dialogs.receivingRanch.form.receivingRanch === null"
            @click="saveSelectedReceivingRanchForAnimals"
            class="buttons white--text"
            color="#3465a1"
          >
            {{ getLabelTranslation("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <div id="app">
          <div v-if="loading">
            <v-progress-circular
              color="primary"
              indeterminate
              size="40"
              width="5"
            ></v-progress-circular>
          </div>

          <!--- Messages --->
          <div v-if="!isOnline" class="alert alert-danger" role="alert">
            You are offline. Report generation is disabled.
          </div>
          <div v-if="messages.danger.length > 0">
            <div
              :key="index"
              class="alert alert-danger"
              role="alert"
              v-for="(message, index) in messages.danger"
            >
              {{ message }}
            </div>
          </div>
          <div v-if="messages.warning.length > 0">
            <div
              :key="index"
              class="alert alert-warning"
              role="alert"
              v-for="(message, index) in messages.warning"
            >
              {{ message }}
            </div>
          </div>
          <div v-if="messages.info.length > 0">
            <div
              :key="index"
              class="alert alert-info"
              role="alert"
              v-for="(message, index) in messages.info"
            >
              {{ message }}
            </div>
          </div>
          <div v-if="messages.success.length > 0">
            <div
              :key="index"
              class="alert alert-success"
              role="alert"
              v-for="(message, index) in messages.success"
            >
              {{ message }}
            </div>
          </div>

          <div id="body" v-cloak>
            <div>
              <v-alert type="info" color="warning" dismissible>
                For the proper functioning of the page and to prevent it from
                freezing due to the large amount of data that can be loaded, the
                minimum date of the start date has been limited to 31 days
                before the end date. Regardless of the end date, the minimum
                date available for the start date will be 31 days before.
              </v-alert>
            </div>
            <div class="g-row">
              <div class="g-col">
                <div class="g-row align-center" style="max-width: 350px">
                  <div class="g-col g-col-1">
                    <v-subheader class="subtitle-1">
                      {{ getTranslation("startDate") }}
                    </v-subheader>
                  </div>
                  <div class="g-col g-col-2">
                    <v-menu
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                      v-model="showStartDateCalendar"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          :disabled="
                            readerGroups.length === 0 || !canGenerateReport
                          "
                          append-icon="mdi-calendar"
                          class="custom-field"
                          dense
                          hide-details
                          outlined
                          readonly
                          tabindex="1"
                          v-bind="attrs"
                          v-model="startDate"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        :min="minimumStartDate"
                        :max="endDate"
                        @input="showStartDateCalendar = false"
                        v-model="startDate"
                      />
                    </v-menu>
                  </div>
                </div>
                <div class="g-row align-center" style="max-width: 350px">
                  <div class="g-col g-col-1">
                    <v-subheader class="subtitle-1">
                      {{ getTranslation("endDate") }}
                    </v-subheader>
                  </div>
                  <div class="g-col g-col-2">
                    <v-menu
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                      v-model="showEndDateCalendar"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          :disabled="
                            readerGroups.length === 0 || !canGenerateReport
                          "
                          append-icon="mdi-calendar"
                          class="custom-field"
                          dense
                          hide-details
                          outlined
                          readonly
                          tabindex="2"
                          v-bind="attrs"
                          v-model="endDate"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        @input="showEndDateCalendar = false"
                        v-model="endDate"
                      />
                    </v-menu>
                  </div>
                </div>
                <div class="g-row align-center" style="max-width: 350px">
                  <div class="g-col g-col-1">
                    <v-subheader class="subtitle-1">
                      {{ getTranslation("reader") }}
                    </v-subheader>
                  </div>
                  <div class="g-col g-col-2">
                    <v-select
                      :items="readerGroups"
                      :menu-props="{
                        offsetY: true,
                        closeOnClick: true,
                      }"
                      class="custom-field"
                      dense
                      hide-details
                      item-text="label"
                      outlined
                      return-object
                      style="max-width: 234px"
                      v-model="readerGroup"
                    />
                  </div>
                </div>
                <div class="g-row pl-4 mt-4">
                  <div class="g-col mr-3" style="max-width: 200px">
                    <v-btn
                      :disabled="
                        !canGenerateReport || !readerGroup.readers.length
                      "
                      @click="generateReport"
                      class="white--text text-decoration-none"
                      color="primary"
                      depressed
                      min-width="40"
                    >
                      {{ getTranslation("Generate") }}
                    </v-btn>
                  </div>
                </div>
              </div>
              <div class="g-col" style="max-width: 400px">
                <!-- Map -->
                <div v-if="mapDialog.show">
                  <v-progress-circular
                    class="flex-grow-1 flex-shrink-1"
                    color="teal"
                    indeterminate
                    size="150"
                    style="height: 50px"
                    v-if="mapDialog.loading"
                    width="13"
                  >
                  </v-progress-circular>
                  <div class="w-100" v-else>
                    <v-btn
                      class="ma-0"
                      v-if="
                        mapDialog.geopointsCollection.length == 0 ||
                        googleMapIsNotAvailable
                      "
                    >
                      {{ getTranslation("Map is not available") }}
                    </v-btn>
                    <div class="w-100" v-else>
                      <div
                        ref="googleMap"
                        style="width: 100%; height: 250px"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Title -->
            <div
              class="pl-4 mt-6"
              v-if="healthTrackerReport && healthTrackerReport.readerGroup"
            >
              <h2 class="no-print">
                {{ healthTrackerReport.readerGroup.label }}
              </h2>
            </div>
            <!-- Controls -->
            <div class="mt-4 mb-3">
              <v-btn
                :disabled="
                  !healthTrackerReport || !healthTrackerReport.readerGroup
                "
                @click="exportToCSVprep"
                class="white--text text-decoration-none"
                color="primary"
                depressed
                min-width="40"
                text
              >
                {{ getTranslation("exportToCSV") }}
              </v-btn>
              <v-btn
                :disabled="disableControlButtons"
                @click="displayDialogMove(rowsSelected)"
                class="white--text text-decoration-none"
                color="primary"
                depressed
                min-width="40"
                text
              >
                {{ getTranslation("move") }}
              </v-btn>
              <v-btn
                :disabled="disableControlButtons"
                @click="displayDialogReceivingRanch(rowsSelected)"
                class="white--text text-decoration-none"
                color="primary"
                depressed
                text
                min-width="40"
              >
                {{ getTranslation("Set Receiving Ranch") }}
              </v-btn>
              <v-btn
                :disabled="listOfNewAnimals.length === 0"
                @click="createNewAnimals"
                class="white--text text-decoration-none"
                color="primary"
                depressed
                text
                min-width="40"
              >
                {{
                  getTranslation("Create") + " " + getTranslation("New Animals")
                }}
              </v-btn>
            </div>
            <v-data-table
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 50, 100, 200, -1],
              }"
              :items-per-page="200"
              :headers="tableHeaders"
              :items="table.data"
              :loading="loadingReport"
              @pagination="handlePagination"
              class="health-tracker-table dashboard-data-table"
              fixed-header
              height="600"
              mobile-breakpoint="0"
              must-sort
              show-select
              v-model="rowsSelected"
            >
              <template #header>
                <thead
                  v-if="healthTrackerReport && healthTrackerReport.statistics"
                >
                  <tr>
                    <th class="fz-1" colspan="7">Statistics</th>
                    <th
                      :key="date"
                      v-for="(
                        statistic, date
                      ) in healthTrackerReport.statistics"
                    >
                      <div class="fz-1" style="white-space: nowrap">
                        Head:
                        {{
                          healthTrackerReport.uniqueAnimalsByDay[date]
                            ? healthTrackerReport.uniqueAnimalsByDay[date]
                            : 0
                        }}
                      </div>
                      <div class="fz-1" style="white-space: nowrap">
                        {{ getTranslation("Min_") }}/{{
                          getTranslation("Max_")
                        }}/{{ getTranslation("Range") }}:
                        {{ statistic.min }}
                        /
                        {{ statistic.max }}
                        /
                        {{ statistic.range }}
                      </div>
                      <div class="fz-1" style="white-space: nowrap">
                        {{ getTranslation("Avg_") }}/{{
                          getTranslation("Med_")
                        }}: {{ statistic.average }}/{{ statistic.median }}
                      </div>
                      <div class="fz-1" style="white-space: nowrap">
                        {{ getTranslation("std_dev") }}:
                        {{ statistic.std_dev }}
                      </div>
                    </th>
                  </tr>
                </thead>
              </template>
              <template #item.eid="{ item }">
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: item.id },
                  }"
                  class="subtitle-2"
                  target="_blank"
                  v-if="item.animalExists"
                >
                  {{ item.eid }}
                </router-link>
                <div v-else>
                  {{ item.eid }}
                </div>
              </template>
              <template #item.animalExists="{ item }">
                <div class="g-row justify-center">
                  <v-btn
                    @click="displayDialogMove([item])"
                    class="white--text text-decoration-none"
                    color="primary"
                    depressed
                    min-width="40"
                    small
                    v-if="item.animalExists"
                  >
                    {{ getTranslation("move") }}
                  </v-btn>
                  <v-btn
                    @click="createAnimal(item)"
                    class="white--text text-decoration-none"
                    color="success"
                    depressed
                    min-width="40"
                    small
                    v-else
                  >
                    {{ getTranslation("create") }}
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";
import { mapGetters } from "vuex";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import TranslationMixin from "../mixins/Translations";
import Vue from "vue";
import axios from "axios";

export default {
  name: "HealthTracker",
  metaInfo: {
    title: "Health Tracker",
  },
  mixins: [TranslationMixin],
  data: () => ({
    broadcast: null,
    dialogs: {
      move: {
        animals: null,
        datePickerStatus: false,
        form: {
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          group: null,
          includeGeopoint: true,
          location: null,
          reason: null,
          status: null,
        },
        locationItems: [],
        show: false,
        showCalendar: false,
      },
      receivingRanch: {
        animals: null,
        datePickerStatus: false,
        form: {
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          includeGeopoint: true,
          groupNumber: null,
          receivingRanch: null,
          status: null,
        },
        show: false,
        showCalendar: false,
      },
    },
    endDate: moment().endOf("day").format("YYYY-MM-DD"),
    healthTrackerReport: null,
    herdMeta: null,
    loadingReport: null,
    loaded: { herdMeta: false, readerGroups: false },
    mapDialog: {
      geopointsCollection: [],
      loading: false,
      show: false,
    },
    messages: {
      danger: [],
      info: [],
      success: [],
      warning: [],
    },
    minimumStartDate: moment()
      .startOf("day")
      .subtract(31, "days")
      .format("YYYY-MM-DD"),
    pouches: null,
    readerGroup: { label: null, readers: [] },
    readerGroups: [],
    reportDates: [],
    showEndDateCalendar: false,
    showStartDateCalendar: false,
    startDate: moment().startOf("day").subtract(2, "days").format("YYYY-MM-DD"),
    rowsSelected: [],
    table: { data: [], selectAll: true },
    timesRun: 0,
    useMockData: false, // Enable this for easy debugging (org: Star S Ranch)
  }),
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
      getToken: "User/getToken",
    }),
    disableControlButtons: function () {
      return (
        this.rowsSelected.length === 0 ||
        this.rowsSelected.some((item) => item.animalIsNew())
      );
    },
    listOfNewAnimals: function () {
      return this.rowsSelected.length > 0
        ? this.rowsSelected.filter((item) => item.animalIsNew())
        : this.table.data.length > 0 &&
          this.table.data.some((item) => !item.animalExists)
        ? this.table.data.filter((item) => item.animalIsNew())
        : [];
    },
    canGenerateReport: function () {
      return (
        this.loaded.herdMeta &&
        this.loaded.readerGroups &&
        this.isOnline &&
        this.readerGroup &&
        this.readerGroups.length &&
        HealthTrackerReport.datesAreProper(this.startDate, this.endDate)
      );
    },
    loading: function () {
      return !this.loaded.herdMeta || !this.loaded.readerGroups;
    },
    statusItems: function () {
      return this.getEnumOptions("statuses").map((item) => {
        return {
          id: item.value.toLowerCase(),
          name: item.label,
        };
      });
    },
    tableHeaders: function () {
      return [
        {
          text: this.getTranslation("EID"),
          type: "default",
          value: "eid",
        },
        {
          text: this.getTranslation("Visuals"),
          type: "default",
          value: "visuals",
          width: 120,
        },
        {
          text: this.getTranslation("Last Read Time"),
          type: "default",
          value: "lastReadTime",
          width: 170,
        },
        {
          text: this.getTranslation("locationList"),
          type: "default",
          value: "location",
          width: 140,
        },
        {
          text: this.getTranslation("tagLot"),
          type: "default",
          value: "lotNumber",
          width: 100,
        },
        {
          sort: (a, b) => {
            if (a === b) {
              return 0;
            } else if (a) {
              return -1;
            } else {
              return 1;
            }
          },
          sortable: true,
          text: this.getTranslation("actions"),
          type: "default",
          value: "animalExists",
        },
      ].concat(this.reportDates);
    },
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.$herdMeta.pouches;
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = (e) => {
      this.generateReport();
    };
    this.herdMeta.loaded
      .fail(() => {
        Vue.notify({
          group: "forms",
          type: "error",
          title: "Error",
          text: "There was a opening the database. Are you still logged in?",
        });
      })
      .done(() => {
        this.loaded.herdMeta = true;
      });

    const { data } = await axios.get("/api/readers", {
      params: {
        token: this.getToken,
      },
    });

    this.readerGroups = HerdMeta.groupOriginReaders(data);
    this.loaded.readerGroups = true;

    if (!this.readerGroups.length) {
      this.messages.info.push("Your organization appears to have 0 readers.");
    } else {
      this.readerGroup = this.readerGroups[0];
    }
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    createNewAnimals: async function () {
      // Iterate over list of new animals and create them
      // Map + async = parallelism with the promises
      // In this case is neccessary to wait until all the promises
      // are resolved before to generate the report and update
      // the rows but in other cases Promise.all is not mandatory
      // we can omit it and just display the results of each promise
      // as they are resolved without waiting to complete all
      try {
        await Promise.all(
          this.listOfNewAnimals.map(async (animal) => {
            await animal.create(this.herdMeta, this.$userID);
          })
        );
        this.generateReport();
      } catch (error) {
        console.log("Error Creating new Animals: ", error);
        this.$notify({
          group: "forms",
          text: "Error creating animals",
          title: "Error",
          type: "error",
        });
      }
    },
    createAnimal: function (animal) {
      animal.create(this.herdMeta, this.$userID);
      this.generateReport();
    },
    handlePagination: function () {
      this.$nextTick(() => {
        this.paintCellsIfContainsZero();
      });
    },
    paintCellsIfContainsZero: function () {
      const cells = document.querySelectorAll("tbody td");

      cells.forEach((cell) => {
        if (cell.textContent === "0") {
          cell.classList.add("bg-red");
        }
      });
    },
    applyStickyHeader: function () {
      const header = document.querySelector("thead.v-data-table-header");
      header.classList.add("sticky-header");
    },
    createGeopointsMap: async function () {
      this.mapDialog.show = true;
      this.mapDialog.loading = true;
      this.mapDialog.geopointsCollection = [];

      if (this.healthTrackerReport && this.healthTrackerReport.coords())
        this.mapDialog.geopointsCollection = [
          {
            lat: +this.healthTrackerReport.coords().latitude,
            lng: +this.healthTrackerReport.coords().longitude,
          },
        ];
      if (this.mapDialog.geopointsCollection.length == 0) {
        this.mapDialog.loading = false;
        return;
      }

      await this.initGoogleMapInstance();
      if (this.googleMapIsNotAvailable) {
        this.mapDialog.loading = false;
        return;
      }
      this.mapDialog.loading = false;
      this.createMapFromGeopoints(this.mapDialog.geopointsCollection);
    },
    createMapFromGeopoints: function (geopointsCollection) {
      this.$nextTick(() => {
        const mapContainer = this.$refs.googleMap;
        const mainMapOption = {
          center: {
            lat: geopointsCollection[0].lat,
            lng: geopointsCollection[0].lng,
          },
          zoom: 10,
        };
        const map = new google.maps.Map(mapContainer, mainMapOption);
        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        });
        const markers = geopointsCollection.map((location) => {
          const marker = new google.maps.Marker({
            label: { text: " ", color: "black" },
            position: location,
          });
          marker.addListener("click", () => {
            infoWindow.setContent(
              `<h5 class="ma-0" style="color:black">${location.lat}, ${location.lng}</h5>`
            );
            infoWindow.open(map, marker);
          });
          return marker;
        });
        new MarkerClusterer({
          markers,
          map,
        });
      });
    },
    displayDialogMove: async function (animals) {
      this.dialogs.move.form = this.$options.data().dialogs.move.form;
      this.dialogs.move.locationItems =
        await this.herdMeta.getMetaLocationsAsync(true, true, true, false);

      this.dialogs.move.animals = animals;
      this.dialogs.move.show = true;
    },
    displayDialogReceivingRanch: async function (animals) {
      this.dialogs.receivingRanch.form =
        this.$options.data().dialogs.receivingRanch.form;
      this.dialogs.receivingRanch.animals = animals;
      this.dialogs.receivingRanch.show = true;
    },
    initGoogleMapInstance: async function () {
      if (!window.google) {
        const googleMapApi = new Loader({
          apiKey: "AIzaSyDTFTTJGbg02sid2CsYocV11bSFvEY5hDg",
        });
        try {
          await googleMapApi.load();
          this.$google = window.google;
        } catch (error) {
          this.googleMapIsNotAvailable = true;
          return;
        }
      }
      this.googleMapIsNotAvailable = false;
    },
    notifySuccess: function (message) {
      this.$notify({
        group: "forms",
        text: message || "Success",
        title: "Success",
        type: "success",
      });
    },
    exportToCSVprep: function () {
      if (!this.healthTrackerReport) return;
      if (
        !this.healthTrackerReport.isLoading() ||
        !confirm(
          "Values are still being loaded. Do you still wish to continue?"
        )
      )
        this.healthTrackerReport.exportToCSV();
    },
    generateReport: function () {
      this.loadingReport = true;
      this.healthTrackerReport = new HealthTrackerReport(
        moment(this.endDate).endOf("day"),
        this.herdMeta,
        this.readerGroup,
        this.startDate,
        this.getToken,
        this.$timezone
      );

      this.reportDates = this.healthTrackerReport.daysInRange.map((day) => ({
        text: "" + day,
        value: "" + day,
        width: 180,
      }));

      this.healthTrackerReport.loading
        .catch((e) => {
          this.healthTrackerReport = null;
          this.$notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: e,
          });
        })
        .then(() => {
          this.table.data = this.healthTrackerReport
            ? this.healthTrackerReport.readerReportAnimals
            : [];
          this.$notify({
            group: "forms",
            type: "success",
            title: "Success",
            text: "Report is complete",
          });
          this.paintCellsIfContainsZero();
          this.applyStickyHeader();
          this.createGeopointsMap();
        })
        .finally(() => (this.loadingReport = false));
    },
    saveAnimalLocation: function (animalId) {
      const d = $.Deferred();

      let timeRecorded = this.dialogs.move.form.date
        ? moment(this.dialogs.move.form.date).toISOString()
        : new Date().toISOString();
      if (
        moment(timeRecorded).format("YYYY-DD-MM") ===
        moment().format("YYYY-DD-MM")
      ) {
        timeRecorded = new Date().toISOString();
      }
      if (moment().diff(timeRecorded, "milliseconds") < 0) {
        this.notifyError(
          this.getLabelTranslation("generics.datePresentOrPastOnly")
        );
        return d.reject();
      }
      const reason = $.trim(this.dialogs.move.form.reason);

      this.pouches.organization
        .get(animalId)
        .then((doc) => {
          const subPromises = [];

          const animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
          const userId = this.$userID;

          if (this.dialogs.move.form.includeGeopoint) {
            const coords = this.healthTrackerReport.coords();
            if (coords) {
              subPromises.push(
                animal.modify("geopoints", null, "id", coords.id, false, true, {
                  ...coords,
                  reason: "Health Tracker: Move",
                  timeRecorded,
                  userId,
                })
              );
            }
          }

          if (
            this.dialogs.move.form.group &&
            this.dialogs.move.form.group.trim().length > 0
          ) {
            subPromises.push(
              animal.modify(
                "movements",
                null,
                "groupNumber",
                this.dialogs.move.form.group.trim(),
                false,
                true,
                {
                  timeRecorded,
                  userId,
                }
              )
            );
          }

          if (reason) {
            subPromises.push(
              animal.modify("comments", null, "comment", reason, false, true, {
                timeRecorded,
                userId,
              })
            );
          }

          if (
            this.dialogs.move.form.status &&
            this.dialogs.move.form.status.id
          ) {
            subPromises.push(
              animal.modify(
                "statuses",
                null,
                "status",
                this.dialogs.move.form.status.id,
                false,
                true,
                {
                  timeRecorded,
                  userId,
                }
              )
            );
          }

          if (this.dialogs.move.form.location.id) {
            const locationId = this.dialogs.move.form.location.id;
            const location = this.dialogs.move.form.location.name;

            subPromises.push(
              animal.modify(
                "movements",
                null,
                "locationId",
                locationId,
                false,
                true,
                {
                  location,
                  reason,
                  timeRecorded,
                  userId,
                }
              )
            );
          }

          $.when.apply($, subPromises).then(() => {
            animal
              .save()
              .fail(console.error)
              .always(() => {
                d.resolve();
              });
          });
        })
        .catch((e) => {
          console.error(e);
          d.reject();
        });

      return d.promise();
    },
    saveAnimalReceivingRanch: function (animalId) {
      const d = $.Deferred();

      let timeRecorded = this.dialogs.receivingRanch.form.date
        ? moment(this.dialogs.receivingRanch.form.date).toISOString()
        : new Date().toISOString();
      if (
        moment(timeRecorded).format("YYYY-DD-MM") ===
        moment().format("YYYY-DD-MM")
      ) {
        timeRecorded = new Date().toISOString();
      }
      if (moment().diff(timeRecorded, "milliseconds") < 0) {
        this.notifyError(
          this.getLabelTranslation("generics.datePresentOrPastOnly")
        );
        return d.reject();
      }

      this.pouches.organization
        .get(animalId)
        .then((doc) => {
          const subPromises = [];

          const animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
          const userId = this.$userID;

          if (this.dialogs.receivingRanch.form.includeGeopoint) {
            const coords = this.healthTrackerReport.coords();
            if (coords) {
              subPromises.push(
                animal.modify("geopoints", null, "id", coords.id, false, true, {
                  ...coords,
                  reason: "Health Tracker: Receiving Ranch",
                  timeRecorded,
                  userId,
                })
              );
            }
          }

          if (
            this.dialogs.receivingRanch.form.groupNumber &&
            this.dialogs.receivingRanch.form.groupNumber.trim()
          ) {
            subPromises.push(
              animal.modify(
                "movements",
                null,
                "groupNumber",
                this.dialogs.receivingRanch.form.groupNumber.trim(),
                false,
                true,
                {
                  timeRecorded,
                  userId,
                }
              )
            );
          }

          if (
            this.dialogs.receivingRanch.form.status &&
            this.dialogs.receivingRanch.form.status.id
          ) {
            subPromises.push(
              animal.modify(
                "statuses",
                null,
                "status",
                this.dialogs.receivingRanch.form.status.id,
                false,
                true,
                {
                  timeRecorded,
                  userId,
                }
              )
            );
          }

          if (
            this.dialogs.receivingRanch.form.receivingRanch &&
            this.dialogs.receivingRanch.form.receivingRanch.id
          ) {
            const receivingRanch =
              this.dialogs.receivingRanch.form.receivingRanch;

            subPromises.push(
              animal.modify(
                "receivingRanches",
                null,
                "receivingRanchId",
                receivingRanch.id,
                false,
                true,
                {
                  organizationId: receivingRanch.id,
                  receivingRanch: receivingRanch.name,
                  timeRecorded,
                  userId,
                }
              )
            );
          }

          $.when.apply($, subPromises).then(() => {
            animal
              .save()
              .fail(console.error)
              .always(() => {
                d.resolve();
              });
          });
        })
        .catch((e) => {
          console.error(e);
          d.reject();
        });

      return d.promise();
    },
    // This function is activated when the user press on "move" button
    saveSelectedLocationForAnimals: function () {
      this.dialogs.move.animals.forEach((animal) => {
        this.saveAnimalLocation(animal.id)
          .fail(console.error)
          .done(() => {
            animal.location = this.dialogs.move.form.location.name;
            this.notifySuccess("Animal moved!");
          });
      });
      this.dialogs.move.show = false;
    },
    saveSelectedReceivingRanchForAnimals: function () {
      this.dialogs.receivingRanch.animals.forEach((animal) => {
        this.saveAnimalReceivingRanch(animal.id)
          .fail(console.error)
          .done(() => {
            animal.location =
              this.dialogs.receivingRanch.form.receivingRanch.name;
            this.notifySuccess("Animal moved!");
          });
      });
      this.dialogs.receivingRanch.show = false;
    },
  },
  watch: {
    endDate: function (newVal) {
      const newEndDate = this.$moment(this.$utils.copyObject(newVal));
      this.minimumStartDate = newEndDate
        .subtract(31, "days")
        .format("YYYY-MM-DD");
    },
  },
};
</script>
<style scoped>
input {
  border: 1px solid #ccc;
}
.fz-1 {
  font-size: 1rem !important;
}
</style>
<style>
.bg-red {
  background-color: #f25252;
}

.sticky-header {
  position: sticky;
  top: 96px;
  z-index: 2;
}
</style>
