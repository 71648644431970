<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h4 class="mr-2 mb-0 title-style" v-if="showTitle">Preg Check History</h4>
      <export-button
        :disabled="table.data.length == 0"
        :filename="`Preg_Check_History ${animalID}-${$utils.renderValueAs(
          new Date().toISOString(),
          'datetime'
        )}`"
        :headers="tableHeaders"
        :items="table.data"
        buttonFormat="icon"
      />
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items-per-page="-1"
      :items="table.data"
      :loading="table.loading"
      class="elevation-1 table-dark-mode"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template #item.actions="{ item }">
        <v-btn
          :class="{
            'disable-cursor': disabled,
          }"
          :disabled="disabled"
          @click="deletePregCheck(item)"
          class="pa-0"
          color="error"
          max-width="40"
          min-width="40"
        >
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template #item.testType="{ item }">
        <v-select
          :disabled="disabled"
          :items="pregCheckTests"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          @change="
            animal.modify(
              'pregChecks',
              item.id,
              'testType',
              item.testType,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          item-text="label"
          outlined
          v-model="item.testType"
        >
        </v-select>
      </template>
      <template #item.status="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'pregChecks',
              item.id,
              'status',
              item.status,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.status"
        >
        </v-text-field>
      </template>
      <template #item.daysBred="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'pregChecks',
              item.id,
              'daysBred',
              item.daysBred,
              true,
              false,
              {
                pregCheckTime: item.pregCheckTime,
                bullTurninDate: item.bullTurninDate,
              }
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.daysBred"
        >
        </v-text-field>
      </template>
      <template #item.bullTurninDate="{ item }">
        <v-menu
          :close-on-content-click="false"
          close-on-click
          min-width="auto"
          offset-y
          transition="scale-transition"
          v-model="showCalendar[item.id]"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :disabled="disabled"
              append-icon="mdi-calendar"
              class="custom-field"
              dense
              hide-details
              outlined
              readonly
              v-bind="attrs"
              v-model="item.bullTurninDate"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            @change="
              animal.modify(
                'pregChecks',
                item.id,
                'bullTurninDate',
                item.bullTurninDate,
                true,
                false,
                {
                  pregCheckTime: item.pregCheckTime,
                  daysBred: item.daysBred,
                }
              )
            "
            v-model="item.bullTurninDate"
          />
        </v-menu>
      </template>
      <template #item.bcs="{ item }">
        <v-select
          :disabled="disabled"
          :items="bcsOptions"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          @change="
            animal.modify('pregChecks', item.id, 'bcs', item.bcs, true, false)
          "
          class="custom-field"
          dense
          hide-details
          item-text="label"
          outlined
          v-model="item.bcs"
        >
        </v-select>
      </template>
      <template #item.fetalSex="{ item }">
        <v-select
          :disabled="disabled"
          :items="fetalSexes"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          @change="
            animal.modify(
              'pregChecks',
              item.id,
              'fetalSex',
              item.fetalSex,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          item-text="label"
          outlined
          v-model="item.fetalSex"
        >
        </v-select>
      </template>
      <template #item.mouthScore="{ item }">
        <v-select
          :disabled="disabled"
          :items="mouthScores"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          @change="
            animal.modify(
              'pregChecks',
              item.id,
              'mouthScore',
              item.mouthScore,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          item-text="mouthScore"
          outlined
          v-model="item.mouthScore.mouthScore"
        >
        </v-select>
      </template>
      <template #item.cycle="{ item }">
        <v-select
          :items="cycles"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          @change="
            animal.modify(
              'pregChecks',
              item.id,
              'cycle',
              item.cycle,
              true,
              false
            )
          "
          class="custom-field"
          dense
          disabled
          hide-details
          item-text="label"
          outlined
          v-model="item.cycle"
        >
        </v-select>
      </template>
      <template #item.method="{ item }">
        <v-select
          :disabled="disabled"
          :items="pregCheckTestMethods"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          @change="
            animal.modify(
              'pregChecks',
              item.id,
              'method',
              item.method,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          item-text="label"
          outlined
          v-model="item.method"
        >
        </v-select>
      </template>
      <template #item.result="{ item }">
        {{ item.result }}
      </template>
      <template #item.technician="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'pregChecks',
              item.id,
              'technician',
              item.technician,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.technician"
        >
        </v-text-field>
      </template>
      <template #item.pregCheckTime="{ item }">
        <div class="g-row">
          <div class="g-col-2">
            {{ $utils.renderValueAs(item.pregCheckTime, "datetime", true) }}
          </div>
          <div class="g-col-1 d-flex align-center">
            <v-btn
              @click="openDatetimeDialog(item, 'pregCheckTime')"
              class="pa-0 ml-1"
              color="#3565A1"
              max-width="32"
              min-width="32"
              small
              v-if="!disabled"
            >
              <v-icon class="white--text">mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <template #item.daysSinceLastPregCheck="{ item }">
        {{
          $utils.getAgeOfTimestamp(item.pregCheckTime, {
            ignoreDays: true,
            ignoreHours: true,
          })
        }}
      </template>
      <template #item.groupNumber="{ item }">
        <v-text-field
          :disabled="disabled"
          @change="
            animal.modify(
              'pregChecks',
              item.id,
              'groupNumber',
              item.groupNumber,
              true,
              false
            )
          "
          class="custom-field"
          dense
          hide-details
          outlined
          v-model="item.groupNumber"
        >
        </v-text-field>
      </template>
      <template #item.predictedCalvingDate="{ item }">
        {{ $utils.renderValueAs(item.predictedCalvingDate, "datetime", true) }}
      </template>
      <template #item.actualCalvingDate="{ item }">
        <div class="g-row">
          <div class="g-col-2">
            {{ item.actualCalvingDate }}
          </div>
          <v-btn
            @click="openDatetimeDialog(item, 'actualCalvingDate')"
            class="pa-0 ml-1 g-col-1"
            color="#3565A1"
            max-width="32"
            min-width="32"
            small
            v-if="!disabled"
          >
            <v-icon class="white--text">mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
      <template #item.actualCalvingDate="{ item }">
        {{ $utils.renderValueAs(item.actualCalvingDate, "datetime", true) }}
      </template>
      <template #item.createdOn="{ item }">
        {{ $utils.renderValueAs(item.createdOn, "datetime", true) }}
      </template>
      <template #item.lastUpdated="{ item }">
        {{ $utils.renderValueAs(item.lastUpdated, "datetime", true) }}
      </template>
    </v-data-table>
    <v-dialog
      max-width="500px"
      scrollable
      transition="dialog-transition"
      v-model="datetimeDialog.show"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>
            {{ datetimeDialog.title }}
          </h4>
          <v-icon class="buttons" @click="datetimeDialog.show = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mt-0" />
        <v-card-text class="py-2">
          <datetime-component v-model="datetimeDialog.datetime" />
        </v-card-text>
        <v-spacer />
        <v-card-actions class="pb-4 pr-6 d-flex justify-end">
          <v-btn
            @click="datetimeDialog.show = false"
            class="mr-2 rounded-lg normal-case body-1"
            text
          >
            {{ getTranslation("animalsPages.cancel") }}
          </v-btn>
          <v-btn
            :disabled="!datetimeDialog.datetime"
            @click="modifyDatetime"
            class="rounded-lg primary-button normal-case body-1"
          >
            <span class="px-6">
              {{ getTranslation("animalsPages.save") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TranslationMixin from "../../mixins/Translations";
export default {
  name: "PregCheckHistory",
  mixins: [TranslationMixin],
  props: {
    animalID: {
      required: true,
      type: String,
    },
    showTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      animal: null,
      broadcast: null,
      datetimeDialog: {
        datetime: null,
        show: false,
        step: 1,
        title: null,
        type: null,
      },
      herdMeta: null,
      lastItem: null,
      mouthScores: [],
      pouches: null,
      showCalendar: {},
      table: {
        data: [],
        loading: true,
      },
    };
  },
  computed: {
    cycles: function () {
      return this.animal
        ? [
            { label: "", value: "" },
            ...this.getEnumOptions("cycles").map((cycle, idx) => {
              if (idx == 0 || idx == 1) {
                cycle.label = `Cycle ${cycle.label}`;
                cycle.value = `Cycle ${cycle.value}`;
              }
              return cycle;
            }),
          ]
        : [];
    },
    disabled: function () {
      return (
        this.loading ||
        (this.animal &&
          (this.animal.disallowChanges() || this.animal.changing()))
      );
    },
    pregResults: function () {
      return this.animal
        ? [{ label: "", value: "" }, ...this.getEnumOptions("pregResults")]
        : [];
    },
    pregCheckTestMethods: function () {
      return this.animal
        ? [
            { label: "", value: "" },
            ...this.getEnumOptions("pregCheckTestMethods"),
          ]
        : [];
    },
    fetalSexes: function () {
      return this.animal
        ? [{ label: "", value: "" }, ...this.getEnumOptions("fetalSexes")]
        : [];
    },
    bcsOptions: function () {
      return this.animal
        ? [{ label: "", value: "" }, ...this.getEnumOptions("bcs")]
        : [];
    },
    pregCheckTests: function () {
      return this.animal
        ? [{ label: "", value: "" }, ...this.getEnumOptions("pregCheckTests")]
        : [];
    },
    tableHeaders: function () {
      return [
        {
          sortable: false,
          text: this.getTranslation("actions"),
          value: "actions",
        },
        {
          text: this.getTranslation("pregCheckTime"),
          value: "pregCheckTime",
          width: 160,
        },
        {
          text: this.getTranslation("Days Bred"),
          value: "daysBred",
          width: 140,
        },
        {
          text: this.getTranslation("daysSincePregCheck"),
          value: "daysSinceLastPregCheck",
        },
        {
          text: this.getTranslation("Bcs"),
          value: "bcs",
          width: 90,
        },
        {
          text: this.getTranslation("Bull Turnin Date"),
          value: "bullTurninDate",
          width: 160,
        },
        {
          text: this.getTranslation("Fetal Sex"),
          value: "fetalSex",
          width: 180,
        },
        {
          text: this.getTranslation("group"),
          value: "groupNumber",
          width: 140,
        },
        {
          text: this.getTranslation("Mouth Score"),
          value: "mouthScore",
          width: 140,
        },
        { text: this.getTranslation("Cycle"), value: "cycle", width: 140 },
        {
          text: this.getTranslation("method"),
          value: "method",
        },
        { text: this.getTranslation("result"), value: "result" },
        {
          text: this.getTranslation("technician"),
          value: "technician",
          width: 140,
        },
        {
          text: this.getTranslation("predictedCalvingDate"),
          value: "predictedCalvingDate",
          width: 160,
        },
        {
          text: this.getTranslation("actualCalvingDate"),
          value: "actualCalvingDate",
          width: 160,
        },
        {
          text: this.getTranslation("createdOn"),
          value: "createdOn",
          width: 160,
        },
        {
          text: this.getTranslation("Last Updated"),
          value: "lastUpdated",
          width: 160,
        },
        // Legacy data
        {
          text: this.getTranslation("Status"),
          value: "status",
          width: 140,
        },
        { text: this.getTranslation("test"), value: "testType", width: 160 },
      ];
    },
  },
  created: function () {
    this.broadcast = new BroadcastChannel("animal_changes");
    this.broadcast.onmessage = async () => {
      await this.init();
    };
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    this.herdMeta.loaded.done(async () => {
      try {
        this.init();
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: this.getTranslation("animalDetails.animalNoExist"),
          title: "Error",
          type: "error",
        });
      }
    });
  },
  beforeDestroy: function () {
    this.broadcast.close();
  },
  methods: {
    init: async function () {
      if (!this.animal) {
        const doc = await this.pouches.organization.get(this.animalID);
        this.animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
      }
      this.getSelectOptions();
      this.getPregCheckHistory();
    },
    getSelectOptions: function () {
      this.mouthScores = [
        { id: "", mouthScore: "" },
        ...this.$utils.copyObject(this.herdMeta.mouthscores),
      ];
    },
    getPregCheckHistory: function () {
      this.table.loading = true;
      this.pouches.organization
        .animalSummaries(true, true, {
          include_docs: true,
          key: this.animalID,
        })
        .then(async (result) => {
          if (result.rows.length === 0) return;
          const summaries = result.rows[0].value;
          this.table.data = summaries.pregCheckHistory || [];
          this.table.data = this.table.data.map((pregCheck) => {
            const mouthScore = pregCheck.mouthScore;
            if (pregCheck.fetalSex)
              pregCheck.fetalSex = pregCheck.fetalSex.toLowerCase();
            if (!mouthScore) pregCheck.mouthScore = { id: "", mouthScore: "" };

            if (
              pregCheck.bullTurninDate &&
              pregCheck.bullTurninDate.includes("T")
            )
              pregCheck.bullTurninDate = this.$utils.formatDate(
                pregCheck.bullTurninDate
              );

            if (
              mouthScore &&
              mouthScore.mouthScore &&
              !this.mouthScores.some(
                (score) => score.mouthScore === mouthScore.mouthScore
              )
            )
              this.mouthScores.splice(1, 0, mouthScore);

            return pregCheck;
          });
          this.table.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.table.loading = false;
        });
    },
    deletePregCheck: async function (item) {
      try {
        await this.animal.deleteRecord("pregChecks", item.id);
        this.$notify({
          group: "forms",
          text: this.getTranslation("Saved"),
          title: "Success",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "forms",
          text: "An error occurred",
          title: "Error",
          type: "error",
        });
      }
    },
    modifyDatetime: function () {
      const type = this.datetimeDialog.type;
      const item = this.datetimeDialog.item;
      const dt = this.$moment(this.datetimeDialog.datetime).format(
        "YYYY-MM-DDTHH:mm"
      );
      if (type == "timeRecorded") {
        this.animal.modify(
          "pregChecks",
          item.id,
          "timeRecorded",
          dt,
          true,
          false
        );
      } else {
        this.animal.modify("pregChecks", item.id, type, dt, true, false, {
          bullTurninDate: item.bullTurninDate,
          daysBred: item.daysBred,
        });
      }
      this.datetimeDialog.show = false;
    },
    openDatetimeDialog: function (item, type) {
      const datetime = Object.assign({}, this.$options.data().datetimeDialog);
      if (!item[type])
        datetime.datetime = this.$moment().format("YYYY-MM-DD HH:mm A");
      else if (type === "timeRecorded")
        datetime.datetime = this.$moment(
          this.$moment(item[type]).toISOString()
        ).format("YYYY-MM-DD HH:mm A");
      else
        datetime.datetime = this.$moment(
          this.$moment(item[type], "YYYY-MM-DD HH:mm A")
        ).format("YYYY-MM-DD HH:mm A");
      datetime.type = type;
      datetime.title = this.getTranslation(type);
      datetime.item = item;
      Object.assign(this.datetimeDialog, datetime);
      this.datetimeDialog.show = true;
    },
  },
};
</script>
<style scoped>
.disable-cursor {
  cursor: default;
}
.title-style {
  font-weight: 600;
  font-size: 22px;
}
</style>
