/* eslint-disable no-console */

import { register } from "register-service-worker";
import Swal from "sweetalert2";

if (process.env.NODE_ENV === "production") {
  // Activate this for a while if service worker gets broken and we need to force it to update
  // navigator.serviceWorker.getRegistrations().then(function (registrations) {
  //   for (let registration of registrations) {
  //     registration.unregister();
  //   }
  // });
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
      console.info(
        "This appears to only fire shortly after registering the service worker. So after a page load, the message appears if ANOTHER reload is required to get the latest code."
      );
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: true,
        timer: 10000,
      });
      Toast.fire({
        icon: "info",
        width: 400,
        padding: "1em",
        background: "rgb(235, 235, 235)",
        text: "Update is available! Please reload.",
        confirmButtonText: "Reload",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
