<template>
  <div class="dashboard-background h-full">
    <in-page-menu />
    <div class="mx-4 mx-lg-8 mx-md-6 pt-20">
      <v-row class="align-center d-flex">
        <v-col
          class="align-center d-flex justify-md-end ml-auto"
          cols="12"
          md="9"
        >
          <v-text-field
            :placeholder="getTranslation('animalsPages.search')"
            class="mb-0 rounded-lg animal-search"
            dense
            hide-details
            outlined
            prepend-inner-icon="mdi-magnify"
            v-model.trim="searchTerm"
          />
          <v-btn
            @click="exportToCSV"
            class="normal-case rounded-lg light-blue-button ml-3"
            text
          >
            <v-icon class="mr-1"> mdi-open-in-new </v-icon>
            CSV
          </v-btn>
          <v-btn
            @click="exportToMapCSV"
            class="normal-case rounded-lg light-blue-button ml-3"
            text
          >
            Map CSV
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="locationsTable.headers"
        :items-per-page="10"
        :items="locationsTable.data"
        :loading="loadingDataTable"
        :search="searchTerm"
        class="dashboard-data-table mb-6"
        hide-default-footer
        loading-text="Loading... Please wait"
        mobile-breakpoint="0"
        show-select
        v-model="selected"
      >
        <template #top="{ options, pagination, updateOptions }">
          <v-data-footer
            :items-per-page-text="getTranslation('animalsPages.itemsPerPage')"
            :options="options"
            :pagination="pagination"
            @update:options="updateOptions"
            class="ml-auto"
          />
        </template>
        <template #header.currentOrganization="{ header }">
          <div class="g-row">
            <div class="g-col g-col-2">
              {{ header.text }}
            </div>
            <div class="g-col g-col-1 justify-center">
              <v-tooltip top v-model="header.showInfoTip">
                <template #activator="{ on, attrs }">
                  <v-btn @click="header.showInfoTip = !header.showInfoTip" icon>
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  You should move the animals from non-existing locations
                </span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template #item.move="{ item }">
          <v-btn
            :disabled="disabledItem(item)"
            @click="showMoveDialog(item.Location)"
            class="h-6 mr-2 primary-button normal-case py-0 rounded-lg"
            text
          >
            Move
          </v-btn>
        </template>
      </v-data-table>
      <animals-widget :syncTime="sync.syncTime" v-bind="staticProps" />
    </div>
    <v-dialog
      max-width="550px"
      scrollable
      transition="dialog-transition"
      v-model="moveDialog.show"
    >
      <v-card>
        <v-card-title>
          <h4>{{ getTranslation("move") }}</h4>
        </v-card-title>
        <v-card-text class="pb-2" style="height: 290px">
          <v-alert type="info" dismissible>
            If you do not see any option to move to, probably is because the
            locations are disabled. You should go to the animal lists page and
            enable them.
          </v-alert>
          <v-col class="d-flex flex-column align-center justify-center">
            <h4 v-if="moveDialog.locationFrom">
              {{ moveDialog.locationFrom.name }}
            </h4>
            <h4 v-if="moveDialog.locationFrom">
              <v-icon>mdi-arrow-down</v-icon>
            </h4>
            <v-autocomplete
              :items="moveDialog.locationsAvailable"
              :menu-props="{ offsetY: true, closeOnClick: true }"
              clearable
              hide-details
              item-text="name"
              outlined
              return-object
              v-model="moveDialog.locationTo"
            />
          </v-col>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer />
          <v-btn
            @click="moveDialog.show = false"
            class="mr-2 rounded-lg normal-case body-1"
            text
          >
            {{ getTranslation("animalsPages.cancel") }}
          </v-btn>
          <v-btn
            :disabled="moveDialog.locationTo === null"
            :loading="changeLoading"
            @click="changeAnimalsLocation"
            class="rounded-lg primary-button normal-case body-1"
          >
            <span class="px-6">
              {{ getTranslation("animalsPages.save") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AnimalsWidget from "@/components/dashboard/AnimalsWidget.vue";
import TranslationMixin from "../mixins/Translations";
import axios from "axios";

export default {
  name: "PastureOrPenHeadCounts",
  components: { AnimalsWidget },
  metaInfo: { title: "Head Counts" },
  data() {
    return {
      changeLoading: false,
      herdMeta: null,
      locations: [],
      locationsTable: { headers: [], data: [], search: "" },
      moveDialog: {
        locationFrom: null,
        locationsAvailable: [],
        locationTo: null,
        show: false,
      },
      pouches: null,
      searchTerm: "",
      selected: [],
      sync: {
        syncTime: new Date().toISOString(),
      },
    };
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    this.pouches = this.herdMeta.pouches;
    await this.init();
  },
  mixins: [TranslationMixin],
  computed: {
    ...mapGetters({
      getActualOrganizationID: "Organization/getOrganizationID",
    }),
    locationsAvailableNotArchived() {
      return this.locations
        .filter(({ archived }) => !archived)
        .filter(({ name }) => name.toLowerCase() !== "not assigned");
    },
    loadingDataTable() {
      return (
        (this.locationsTable.headers.length == 0 &&
          this.locationsTable.data.length == 0) ||
        this.changeLoading
      );
    },
    staticProps() {
      return {
        "herd-meta": this.herdMeta,
        pouches: this.pouches,
      };
    },
  },
  methods: {
    disabledItem(item) {
      return (
        item["Total Head"] === 0 ||
        (item["Location"] !== "Not assigned" &&
          this.locationsAvailableNotArchived.length === 1 &&
          this.locationsAvailableNotArchived[0].name === item["Location"])
      );
    },
    // This is temporary, just to test the new service of get head_counts.csv
    exportToMapCSV: async function () {
      try {
        const response = await axios.get(
          `/api/head_counts_per_location/${this.$organizationID}`,
          {
            params: {
              token: "3a66817d-1249-11ef-ae59-0242ac120003",
            },
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Head_Counts_${this.$organizationID}_${new Date().toISOString()}.csv`
        );

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }
    },
    exportToCSV: async function () {
      const headers = this.locationsTable.headers.filter((item) => item.text);
      const fields = headers.map((item) => item.text);
      const data = this.selected.map((herd) =>
        headers.map(({ value }) => herd[value])
      );
      this.herdMeta.exportToCSV(fields, data, "pastureOrPenHeadCounts.csv");
    },
    locationHasAnimals(location) {
      return this.locationsTable.data.some(
        ({ locationId }) => locationId === location
      );
    },
    showMoveDialog(locationName) {
      this.moveDialog.locationTo = null;
      this.moveDialog.show = true;
      if (locationName.toLowerCase() === "not assigned") {
        this.moveDialog.locationFrom = { name: locationName };
        this.moveDialog.locationsAvailable = this.locationsAvailableNotArchived;
      } else {
        this.moveDialog.locationFrom = this.locations.find(
          ({ name }) => name == locationName
        ) || {
          // If it is not in locations, then it is from another organization OR the name of the location changed
          id: null,
          name: locationName,
        };
        this.moveDialog.locationsAvailable =
          this.locationsAvailableNotArchived.filter(
            ({ id }) => id != this.moveDialog.locationFrom.id
          );
      }
    },
    _userFriendlyLocationName(location) {
      return location == null ? "Not assigned" : location;
    },
    _userFriendlySexName(sex) {
      return sex == null ? "Not set" : sex;
    },
    getValuesAsync() {
      const d = $.Deferred();
      this.pouches.organization
        .query("local_views/animalsWithMainSummaryOnly")
        .then(async (results) => {
          const summaries = results.rows
            .map((result) => result.value.main)
            .filter((summary) => {
              const status = (summary.status || "").toLowerCase();
              return ["alive", "cull", "marketable", "sick"].some(
                (s) => s == status
              );
            });
          const locations = Object.keys(
            summaries
              .map((summary) => summary.location)
              // Ignore animals in a real location called not assigned
              .filter(
                (location) =>
                  !location || location.toLowerCase() !== "not assigned"
              )
              .reduce((reduction, location) => {
                if (location == null) return reduction;
                reduction[this._userFriendlyLocationName(location)] = true;
                return reduction;
              }, {})
          )
            .sort()
            .concat(this._userFriendlyLocationName(null));
          const metaSexes = await this.herdMeta.getMetaSexesAsync(
            false,
            false,
            false
          );
          const sexes = Object.keys(
            summaries
              .map((summary) => summary.sex)
              .reduce((reduction, sex) => {
                if (sex == null) return reduction;
                if (
                  sex.includes("-") ||
                  (sex.includes("_") &&
                    metaSexes.some((item) => item.id == sex))
                )
                  sex = metaSexes.find((item) => item.id == sex).sex;

                reduction[this._userFriendlySexName(sex)] = true;
                return reduction;
              }, {})
          )
            .sort()
            .concat(this._userFriendlySexName(null));
          // Set with nulls
          let tableData = locations.reduce((reduction, location) => {
            reduction[location] = sexes.reduce((reduction, sex) => {
              reduction[sex] = 0;
              return reduction;
            }, {});
            return reduction;
          }, {});

          // Set with actual values
          summaries.forEach((summary) => {
            // attribute to totals for each location and sex
            tableData[this._userFriendlyLocationName(summary.location)][
              this._userFriendlySexName(summary.sex)
            ]++;
          });

          this.herdMeta
            .getMetaLocationsAsync(false, false, false)
            .done((allLocations) => {
              const allLocationsByName = allLocations.reduce(
                (reduction, location) => {
                  reduction[location.name] = location;
                  return reduction;
                },
                {}
              );

              tableData = locations.map((location) => {
                const isArchived = (
                  allLocationsByName[location] || { archived: false }
                ).archived;

                if (allLocationsByName[location])
                  tableData[location]["currentOrganization"] = "Yes";
                else tableData[location]["currentOrganization"] = "No";
                // Remove from list
                delete allLocationsByName[location];

                tableData[location]["id"] = Utils.guid();
                tableData[location]["Location"] = location;
                tableData[location]["Archived"] = isArchived;
                tableData[location]["Total Head"] = sexes
                  .map((sex) => {
                    return tableData[location][sex];
                  })
                  .reduce((reduction, total) => reduction + total, 0);

                return tableData[location];
              });

              // Remaining locations are empty
              Object.keys(allLocationsByName)
                // But no need to display data for archived ones
                .filter(
                  (locationName) => !allLocationsByName[locationName].archived
                )
                .forEach((location) => {
                  let data = {
                    id: allLocationsByName[location].id,
                    Location: allLocationsByName[location].name,
                    Archived: false,
                    "Total Head": 0,
                  };
                  sexes.forEach((sex) => (data[sex] = 0));
                  tableData.push(data);
                });

              let columns = ["id", "Location"]
                .concat(
                  tableData.filter((location) => location.Archived).length
                    ? ["Archived"]
                    : []
                )
                .concat(["Total Head"])
                .concat(sexes);
              tableData.forEach(
                (location) =>
                  (location.Archived = location.Archived ? "Yes" : "No")
              );
              tableData = tableData.map((data) => {
                Object.defineProperty(
                  data,
                  "Notset",
                  Object.getOwnPropertyDescriptor(data, "Not set")
                );
                Object.defineProperty(
                  data,
                  "TotalHead",
                  Object.getOwnPropertyDescriptor(data, "Total Head")
                );
                return data;
              });
              columns = columns.map((column) => {
                return {
                  data: column,
                  name: column,
                };
              });
              const locationsCoords = allLocations.reduce((prev, current) => {
                if (!prev[current])
                  prev[current.name] = {
                    latitude: current.latitude,
                    longitude: current.longitude,
                  };
                return prev;
              }, {});
              tableData = tableData.map((row) => {
                const currentCoords = !!locationsCoords[row.Location]
                  ? locationsCoords[row.Location]
                  : null;
                return {
                  ...row,
                  latitude: currentCoords && currentCoords.latitude,
                  longitude: currentCoords && currentCoords.longitude,
                };
              });
              this.upsertHeadCountsInPouch(tableData);
              d.resolve(tableData, columns);
            });
        });

      return d.promise();
    },
    upsertHeadCountsInPouch: function (tableData) {
      this.pouches.organization
        .get("head_counts")
        .then((doc) => {
          // Update _rev only if doc.data is different of tableData
          let updatePouch = false;
          if (doc.data.length === tableData.length) {
            tableData.forEach((item, idx) => {
              Object.keys(item).forEach((prop) => {
                if (!Object.keys(doc.data[idx]).includes(prop)) {
                  updatePouch = true;
                  return;
                }
                if (
                  prop !== "id" &&
                  String(item[prop]) != String(doc.data[idx][prop])
                ) {
                  updatePouch = true;
                  return;
                }
              });
            });
          } else updatePouch = true;
          if (updatePouch) {
            this.pouches.organization.put({
              _id: "head_counts",
              _rev: doc._rev,
              data: tableData,
            });

            if (this.isOnline)
              this.pouches.organization.replicate.to(
                `https://admin:${this.$pouchPass}@${window.location.host}/organization-${this.$organizationID}`,
                {
                  filter: (doc) => {
                    return !doc._id.startsWith("_design/local_");
                  },
                  live: false,
                  style: "all_docs",
                }
              );
          }
        })
        .catch((e) => {
          // Create head_counts for the first time
          if (e.error === "not_found") {
            this.pouches.organization.put({
              _id: "head_counts",
              data: tableData,
            });
            if (this.isOnline)
              this.pouches.organization.replicate.to(
                `https://admin:${this.$pouchPass}@${window.location.host}/organization-${this.$organizationID}`,
                {
                  filter: (doc) => {
                    return !doc._id.startsWith("_design/local_");
                  },
                  live: false,
                  style: "all_docs",
                }
              );
          }
        });
    },
    init() {
      this.changeLoading = true;
      this.herdMeta
        .getMetaLocationsAsync(true, true, true)
        .done((locations, _locationsById) => {
          this.locations = locations.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        });
      return new Promise((resolve, _) => {
        this.herdMeta.loaded.done(() => {
          this.getValuesAsync()
            .always(() => {
              $(".loading").hide();
            })
            .fail((_e) => {
              console.log("Error ocurred");
              console.log(_e);
            })
            .done(async (tableData, columns) => {
              this.locationsTable.headers = [];
              this.locationsTable.headers.push(
                {
                  text: this.getLabelTranslation("pastureSlashPen"),
                  value: "Location",
                },
                {
                  sortable: false,
                  text: "Move",
                  value: "move",
                },
                {
                  showInfoTip: false,
                  sortable: false,
                  text: "Belongs to the current org",
                  value: "currentOrganization",
                  width: 130,
                }
              );
              this.locationsTable.headers = this.locationsTable.headers.concat(
                columns
                  .filter(({ name }) => name != "id")
                  .filter(({ name }) => name != "Location")
                  .map(({ data, name }) => ({
                    text: name,
                    value: data,
                  }))
              );
              if (
                !this.locationsTable.headers.some(
                  (element) => element.text === "Archived"
                )
              )
                this.locationsTable.headers.push({
                  text: "Archived",
                  value: "Archived",
                });
              this.locationsTable.data = tableData;
              this.changeLoading = false;
              resolve();
            });
        });
      });
    },
    groupAnimalsByLocation(locationName) {
      return new Promise((resolve, reject) => {
        this.pouches.organization
          .query("local_views/animalsWithMainSummaryOnly")
          .then(async (results) => {
            results = results.rows
              .filter((item) => {
                // Get locationID
                // Find location
                const itemLocation = item.value.main;
                if (!itemLocation.locationId) return false;
                const currentLocation = this.locations.find(
                  (location) => location.id === itemLocation.locationId
                );
                return currentLocation && currentLocation.name
                  ? currentLocation.name.toLowerCase() ==
                      locationName.toLowerCase()
                  : itemLocation.location
                  ? itemLocation.location.toLowerCase() ==
                    locationName.toLowerCase()
                  : false;
              })
              .map(({ id }) => ({ id }));
            try {
              results = await this.pouches.organization.bulkGet({
                docs: results,
              });
            } catch (error) {
              console.log("Error bulkGet", error);
              resolve([]);
              return;
            }
            results = results.results
              .map(({ id, docs }) => ({
                id,
                doc: docs[0].ok,
              }))
              .map(async ({ id, doc }) => {
                const animal = new Animal(id, this.herdMeta, doc, this.$userID);
                return animal;
              });
            Promise.all(results).then((data) => {
              resolve(data);
            });
          });
      });
    },
    async changeAnimalsLocation() {
      const from = this.moveDialog.locationFrom;
      const to = this.moveDialog.locationTo;
      const fromAnimalsGroup = await this.groupAnimalsByLocation(from.name);
      const promises = [];
      if (fromAnimalsGroup.length == 0) {
        this.moveDialog.show = false;
        this.$notify({
          group: "forms",
          text: "An error occurred.",
          title: "Error",
          type: "danger",
        });
        return;
      }
      fromAnimalsGroup.forEach((animal) => {
        promises.push(
          animal.modify("movements", null, "locationId", to.id, false, false, {
            userId: this.$userID,
            timeRecorded: new Date().toISOString(),
          })
        );
      });
      $.when
        .apply($, promises)
        .then(() => {
          this.$notify({
            group: "forms",
            text: `Location updated for ${fromAnimalsGroup.length} animals`,
            title: "Success",
            type: "success",
          });
        })
        .fail((e) => {
          this.$notify({
            text: "An error occurred.",
            group: "forms",
            title: "Error",
            type: "danger",
          });
        })
        .always(() => {
          this.moveDialog.show = false;
          this.getValuesAsync().done(async (tableData, columns) => {
            this.upsertHeadCountsInPouch(tableData);
            this.locationsTable.data = tableData;
          });
        });
    },
  },
};
</script>

<style scoped>
.animal-search {
  max-width: 300px;
}
>>> .theme--light.animal-search fieldset {
  border-color: rgba(0 0 0 / 0.2);
}
>>> .mdi-checkbox-marked.theme--light,
>>> .mdi-minus-box.theme--light {
  color: var(--dark-blue);
}
</style>
