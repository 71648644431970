<template>
  <div @keydown="captureCtrlKey" tabindex="-1" class="dashboard-background">
    <in-page-menu :activeBadge="getRowsCount || '?'" />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-col cols="12">
        <!-- Filters Inputs -->
        <div style="max-height: 400px" class="d-flex flex-wrap py-1">
          <!-- Move Columns -->
          <v-autocomplete
            :items="sortedTableHeaders"
            @input="moveColumn"
            class="flex-shrink-1 flex-grow-0 mr-2 mediumFont mb-2"
            clearable
            dense
            hide-details
            item-text="text"
            item-value="value"
            outlined
            prepend-inner-icon="mdi-magnify"
            style="min-width: 260px; max-width: 260px; font-size: 0.875rem"
            v-model="inputs.moveColumn"
          >
            <template #label>
              <p class="ma-0 mediumFont">
                {{ getTranslation("Move Column") }}
              </p>
            </template>
          </v-autocomplete>
          <!-- Filter By Group Number -->
          <v-autocomplete
            :items="options.groupNumbers"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            @input="filterData"
            chips
            class="flex-shrink-1 flex-grow-0 mr-2 mediumFont mb-2"
            clearable
            dense
            hide-details
            multiple
            outlined
            prepend-inner-icon="mdi-magnify"
            style="max-width: 260px; min-width: 260px"
            v-if="toggleFilters.filters.groupNumber"
            v-model="inputs.selectedGroupNumbers"
          >
            <template #selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>{{ item }}</span>
              </v-chip>
              <span v-if="index == 3" class="grey--text text-caption">
                (+{{ inputs.selectedGroupNumbers.length - 3 }}
                others)
              </span>
            </template>
            <template #label>
              <p class="ma-0 mediumFont">
                {{ getTranslation("Group Number") }}
              </p>
            </template>
          </v-autocomplete>
          <!-- Filter By Herds -->
          <v-autocomplete
            :items="options.herds"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            @input="filterData"
            chips
            class="flex-shrink-1 flex-grow-0 mr-2 mediumFont mb-2"
            clearable
            dense
            hide-details
            multiple
            outlined
            prepend-inner-icon="mdi-magnify"
            style="max-width: 260px; min-width: 260px"
            v-if="toggleFilters.filters.herd"
            v-model="inputs.selectedHerds"
          >
            <template #selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>{{ item }}</span>
              </v-chip>
              <span v-if="index == 3" class="grey--text text-caption">
                (+{{ inputs.selectedHerds.length - 3 }}
                others)
              </span>
            </template>
            <template #label>
              <p class="ma-0 mediumFont">
                {{ getTranslation("Herd") }}
              </p>
            </template>
          </v-autocomplete>
          <!-- Filter By Status -->
          <v-select
            :items="statusFilterOptions"
            :menu-props="{ offsetY: true, closeOnClick: true }"
            @input="filterData"
            class="flex-shrink-1 flex-grow-0 mr-2 mediumFont mb-2"
            dense
            hide-details
            item-text="label"
            outlined
            prepend-inner-icon="mdi-magnify"
            style="max-width: 260px; min-width: 260px"
            v-if="toggleFilters.filters.statusFilter"
            v-model="inputs.selectedStatus"
          >
            <template #label>
              <p class="ma-0 mediumFont">
                {{ getTranslation("Status") }}
              </p>
            </template>
          </v-select>
          <!-- Layout Selector -->
          <div>
            <v-select
              :append-outer-icon="
                layouts.available.length < 6 ? 'mdi-plus-box' : ''
              "
              :items="layouts.available"
              :menu-props="{ offsetY: true, closeOnClick: true }"
              @click:append-outer="layouts.dialogs.showCreate = true"
              class="flex-shrink-1 flex-grow-0 mr-2 mediumFont mb-2"
              dense
              hide-details
              label="Layout"
              outlined
              style="max-width: 300px"
              v-model="layouts.current"
            >
              <template #item="{ attrs, item, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title> {{ item }} </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-if="item !== 'default'">
                    <v-btn
                      @click="openEditLayoutDialog(item)"
                      color="primary"
                      icon
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      @click="openDeleteLayoutDialog(item)"
                      color="primary"
                      icon
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
        <v-data-table
          :class="{
            'selected-dark': $vuetify.theme.dark,
            'selected-light': !$vuetify.theme.dark,
          }"
          :custom-sort="sortVisualTag"
          :footer-props="{
            'items-per-page-options': itemsPerPage,
          }"
          :headers="table.headers"
          :items-per-page.sync="table.rowsPerPage"
          :items="table.data"
          :loading="table.loading"
          @click:row="selectItemsClicked"
          @current-items="getFilteredItems"
          @pagination="setItemsLengthToBadge"
          class="d-flex flex-column"
          fixed-header
          height="560"
          id="animalsTable"
          loading-text="Loading..."
          mobile-breakpoint="0"
          must-sort
          ref="animalsTable"
          show-expand
          sort-by="visualTagValues"
          v-model="table.selected"
        >
          <template #top>
            <div class="mb-10 d-flex flex-wrap">
              <div class="flex-grow-1 flex-shrink-1 mb-2">
                <!-- Show/Hide Toggle -->
                <v-menu offset-y :close-on-content-click="false">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      depressed
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      Toggle
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="overflow-y-auto" style="max-height: 400px">
                    <v-list-item
                      :key="idx"
                      style="max-height: 40px"
                      v-for="(header, idx) in prevTableHeaders || tableHeaders"
                    >
                      <v-checkbox
                        @change="toggleColumnsVisibility"
                        dense
                        v-model="header.visible"
                      >
                        <template #label>
                          <div class="mt-2">
                            {{ header.text }}
                          </div>
                        </template>
                      </v-checkbox>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- Move -->
                <v-btn
                  :disabled="table.selected.length == 0"
                  @click="moveDialog.show = true"
                  color="primary"
                  depressed
                  text
                >
                  Move
                </v-btn>
                <!-- Edit -->
                <v-menu offset-y style="z-index: 204">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      depressed
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      Edit
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    class="overflow-y-auto"
                    dense
                    style="max-height: 400px"
                  >
                    <v-list-item
                      :disabled="disabled"
                      :key="idx"
                      @click="showDialog"
                      v-for="(
                        { name, text, showDialog, disabled }, idx
                      ) in editors"
                    >
                      <v-list-item-title
                        :class="{
                          'red--text': !disabled && redEditors.includes(name),
                        }"
                        >{{ text }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- Filters -->
                <v-btn
                  @click="toggleFilters.show = true"
                  color="primary"
                  depressed
                  text
                >
                  Filters
                </v-btn>
                <!-- Map -->
                <v-btn
                  :disabled="table.selected.length == 0"
                  @click="createGeopointsMap"
                  color="primary"
                  depressed
                  text
                >
                  Map
                </v-btn>
                <!-- Reset Search -->
                <v-btn
                  @click="resetSearchDialog.show = true"
                  color="primary"
                  depressed
                  text
                >
                  Reset Search
                </v-btn>
                <!-- Export -->
                <export-button
                  :disabled="table.selected.length == 0"
                  :filename="`Animal_Report_${new Date().toISOString()}`"
                  :headers="table.headers"
                  :items="table.selected"
                />
              </div>
              <div class="ml-auto d-flex flex-wrap justify-center">
                <!-- Tablet Mode -->
                <v-switch
                  @change="toggleTabletMode"
                  class="mt-0 py-2 mr-4 tablet-mode"
                  color="primary"
                  hide-details
                  label="Tablet Mode"
                  v-model="table.tabletMode"
                >
                </v-switch>
                <v-btn
                  :disabled="disableReload"
                  @click="init(true)"
                  class="mt-0 py-2 mr-4"
                  depressed
                  text
                >
                  <v-icon :color="sync.reloadSuggested ? 'red' : 'primary'">
                    mdi-reload
                  </v-icon>
                </v-btn>
                <!-- Global Search -->
                <v-text-field
                  @click:clear="resetRowsPerPage()"
                  @input="runGlobalSearch"
                  append-icon="mdi-magnify"
                  class="flex-grow-0 flex-shrink-1 mr-2"
                  clearable
                  dense
                  hide-details
                  label="Search"
                  outlined
                  style="max-width: 250px"
                  v-model="table.search"
                ></v-text-field>
              </div>
            </div>
          </template>
          <template #item.id="{ item }">
            <router-link
              :to="{ name: 'AnimalDetails', query: { id: item.id } }"
              class="white--text"
              style="text-decoration: none"
              target="_blank"
            >
              <v-btn class="pa-0" color="primary"> View </v-btn>
            </router-link>
          </template>
          <template #item.aiSireTags="{ item }">
            <router-link
              :to="{ name: 'AnimalDetails', query: { id: item.aiSireId } }"
              class="m-2 white--text"
              style="text-decoration: none"
              target="_blank"
              v-if="item.aiSireTags && item.aiSireId"
            >
              <v-btn class="pa-0" color="primary">
                {{ item.aiSireTags }}
              </v-btn>
            </router-link>
          </template>
          <template #item.description="{ item }">
            <div v-html="item.description"></div>
          </template>
          <template #item.alerts="{ item }">
            <div v-html="item.alerts"></div>
          </template>
          <template #item.headDays="{ item }">
            <span
              :title="
                Animal.getAgeOfDuration($moment.duration(item.headDays, 'days'))
              "
              >{{ item.headDays }}</span
            >
          </template>
          <template #header="{ props }">
            <draggable
              :style="{
                'background-color': $vuetify.theme.dark ? '#1E1E1E' : 'white',
              }"
              @end="reorderColumns"
              class="reorder-sticky"
              draggable=".draggable"
              tag="tr"
            >
              <td
                :class="{ draggable }"
                :id="value"
                :key="idx + value"
                style="height: 48px"
                v-for="({ value, draggable }, idx) in props.headers"
              >
                <v-icon
                  :id="value"
                  class="d-flex justify-center"
                  small
                  style="height: 100%"
                  v-if="draggable"
                >
                  mdi-arrow-all
                </v-icon>
                <div style="height: 100%" v-else></div>
              </td>
            </draggable>
            <!-- </tr> -->
            <tr
              :style="{
                'background-color': $vuetify.theme.dark ? '#1E1E1E' : 'white',
              }"
              class="sticky-header"
            >
              <td
                :key="idx"
                v-for="({ text, searchable, value }, idx) in props.headers"
              >
                <v-divider class="my-0"></v-divider>
                <div
                  class="my-1"
                  style="height: 48px; min-width: 120px"
                  v-if="searchable"
                >
                  <v-text-field
                    :label="text"
                    hide-details
                    solo
                    v-model="table.columnSearches[value]"
                  ></v-text-field>
                </div>
                <div
                  class="my-1 d-flex justify-center align-center"
                  style="height: 48px"
                  v-else-if="value == 'data-table-expand'"
                >
                  <v-checkbox
                    @change="toggleSelectAll"
                    class="my-0"
                    hide-details
                    v-if="!table.loading"
                    v-model="table.selectAll"
                  ></v-checkbox>
                </div>
                <div class="my-1" style="height: 48px" v-else></div>
                <v-divider class="my-0"></v-divider>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <td class="pt-2 pl-0" :colspan="headers.length">
              <div :key="idx" v-for="(col, idx) in invisibleHeaders">
                <div v-if="!!item[col.value]">
                  <p class="mb-0 pl-2">
                    {{ col.text }} : {{ item[col.value] }}
                  </p>
                  <v-divider class="my-2"></v-divider>
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
        <!-- Dialogs -->
        <div>
          <!-- Create Layout -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="layouts.dialogs.showCreate"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Create New Layout</h4>
                <v-icon
                  class="buttons"
                  @click="layouts.dialogs.showCreate = false"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2">
                <v-text-field
                  autofocus
                  clearable
                  hide-details
                  label="Layout"
                  outlined
                  v-model="layouts.dialogs.layout"
                ></v-text-field>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="
                    $utils.isWhitespace(layouts.dialogs.layout) ||
                    $utils.isValInArray(
                      layouts.available,
                      layouts.dialogs.layout.trim()
                    )
                  "
                  @click="createLayout"
                  color="success"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Edit Layout Name -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="layouts.dialogs.showEdit"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit Layout</h4>
                <v-icon
                  class="buttons"
                  @click="layouts.dialogs.showEdit = false"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2">
                <v-text-field
                  autofocus
                  clearable
                  hide-details
                  label="Layout"
                  outlined
                  v-model="layouts.dialogs.newLayout"
                ></v-text-field>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="
                    $utils.isWhitespace(layouts.dialogs.newLayout) ||
                    layouts.dialogs.newLayout.trim().toLowerCase() ==
                      layouts.dialogs.previousLayout.trim().toLowerCase()
                  "
                  @click="editLayout"
                  color="success"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Delete Layout -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="layouts.dialogs.showDelete"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Delete Layout</h4>
                <v-icon
                  class="buttons"
                  @click="layouts.dialogs.showDelete = false"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2">
                Are you sure you want to delete this layout?
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn @click="deleteLayout" color="error"> Delete </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Move Location -->
          <v-dialog
            :overlay="false"
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="moveDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>{{ getTranslation("move") }}</h4>
                <v-icon class="buttons" @click="moveDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text style="height: 280px">
                <v-select
                  :items="moveDialog.locationItems"
                  :label="getLabelTranslation('pastureSlashPen')"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="name"
                  outlined
                  return-object
                  v-model="moveDialog.form.location"
                ></v-select>
                <v-select
                  :items="statusItems"
                  :label="getTranslation('statusList')"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="name"
                  item-value="key"
                  outlined
                  v-model="moveDialog.form.status"
                ></v-select>
                <v-text-field
                  :label="getTranslation('reason')"
                  clearable
                  hide-details
                  outlined
                  v-model="moveDialog.form.reason"
                ></v-text-field>
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="moveDialog.showCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :label="getTranslation('dateRecorded')"
                      append-icon="mdi-calendar"
                      class="my-2"
                      hide-details
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="moveDialog.form.date"
                      v-on:click:append="moveDialog.showCalendar = true"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="moveDialog.showCalendar = false"
                    v-model="moveDialog.form.date"
                  ></v-date-picker>
                </v-menu>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="
                    !moveDialog.form.reason ||
                    moveDialog.form.reason.trim().length == 0
                  "
                  @click="moveAnimalsLocation"
                  class="buttons white--text"
                  color="#3465a1"
                  >{{ getTranslation("moveButton") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Force Update -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="forceUpdateDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Force Update</h4>
                <v-icon class="buttons" @click="forceUpdateDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 60px">
                Forcefully update record(s)?
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn @click="forceUpdate" color="success">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Selective Delete By Date -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="selectiveDeleteDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Delete Attribute</h4>
                <v-icon
                  class="buttons"
                  @click="selectiveDeleteDialog.show = false"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 365px">
                <h5>
                  {{ getTranslation("Date Type") }}
                </h5>
                <v-radio-group v-model="selectiveDeleteDialog.form.dateType">
                  <v-radio value="createdOn">
                    <template #label>
                      <div>
                        <h5 class="ma-0 mt-2">
                          {{ getTranslation("Created/Entered/Saved") }}
                        </h5>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="timeRecorded">
                    <template #label>
                      <div>
                        <h5 class="ma-0 mt-2">
                          {{ getTranslation("Effective Date") }}
                        </h5>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <div class="mb-2">
                  <v-menu
                    :close-on-content-click="false"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                    v-model="selectiveDeleteDialog.showCalendar"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        append-icon="mdi-calendar"
                        hide-details
                        outlined
                        readonly
                        style="font-size: 1.3rem"
                        v-bind="attrs"
                        v-model="selectiveDeleteDialog.form.date"
                        v-on:click:append="
                          selectiveDeleteDialog.showCalendar = true
                        "
                        v-on="on"
                      >
                        <template #label>
                          <p
                            style="font-size: 1.5rem; font-weight: 'bold'"
                            class="ma-0"
                          >
                            {{ getTranslation("date") }}
                          </p>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      @input="selectiveDeleteDialog.showCalendar = false"
                      v-model="selectiveDeleteDialog.form.date"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <v-select
                  :items="selectiveDeleteDialog.optionsTypes"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  hide-details
                  item-text="value"
                  item-value="key"
                  outlined
                  style="font-size: 1.9rem"
                  v-model="selectiveDeleteDialog.form.type"
                >
                  <template #label>
                    <div
                      :class="{ darkInputText: $vuetify.theme.dark }"
                      style="font-size: 1.9rem"
                    >
                      Type
                    </div>
                  </template>
                </v-select>
                <div
                  class="mb-2"
                  v-if="selectiveDeleteDialog.form.type == 'groupNumber'"
                >
                  <v-text-field
                    clearable
                    hide-details
                    outlined
                    style="font-size: 1.3rem"
                    v-model="selectiveDeleteDialog.form.groupNumber"
                  >
                    <template #label>
                      <p
                        style="font-size: 1.5rem; font-weight: 'bold'"
                        class="ma-0"
                      >
                        {{ getTranslation("fields.groupNumber") }}
                      </p>
                    </template>
                  </v-text-field>
                </div>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="
                    !selectiveDeleteDialog.form.type ||
                    selectiveDeleteDialog.form.dateType == 0 ||
                    (selectiveDeleteDialog.form.type == 'groupNumber' &&
                      (!selectiveDeleteDialog.form.groupNumber ||
                        selectiveDeleteDialog.form.groupNumber.trim().length ==
                          0))
                  "
                  @click="runSelectiveDelete"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Comment -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="commentDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Add Comment</h4>
                <v-icon class="buttons" @click="commentDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 80px">
                <v-text-field
                  clearable
                  hide-details
                  label="Comment"
                  outlined
                  v-model="commentDialog.comment"
                ></v-text-field>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="
                    !commentDialog.comment || commentDialog.comment.length == 0
                  "
                  @click="editComment"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Tag -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="tagDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Add Tag</h4>
                <v-icon class="buttons" @click="tagDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 210px">
                <v-select
                  :items="tagDialog.tagsAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="id"
                  label="Tag Table"
                  outlined
                  v-model="tagDialog.tagTable"
                ></v-select>
                <v-select
                  :items="tagDialog.typesAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Type"
                  outlined
                  v-model="tagDialog.type"
                ></v-select>
                <v-text-field
                  clearable
                  hide-details
                  label="Tag Value"
                  outlined
                  v-model="tagDialog.tagValue"
                ></v-text-field>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="
                    (!tagDialog.tagTable && !tagDialog.type) ||
                    !tagDialog.tagValue ||
                    tagDialog.tagValue.length == 0
                  "
                  @click="editTag"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Bred To -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="bredToDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4 class="mb-0">Edit Bred To</h4>
                <v-icon class="buttons" @click="bredToDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="pt-0 pb-2">
                <search-animal
                  :allowedStatuses="['semen']"
                  :showEID="false"
                  @search-result="getSireResult"
                  class="mb-2"
                  dense
                  emptyMessage="Error: There are no animals with status semen"
                  emptyVisualsErrorLevel="danger"
                  hideTooltip
                  visualTitle="Sire"
                />
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="bredToDialog.showCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :label="getTranslation('dateRecorded')"
                      append-icon="mdi-calendar"
                      class="px-3 mb-2 custom-field"
                      dense
                      hide-details
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="bredToDialog.date"
                      v-on:click:append="bredToDialog.showCalendar = true"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="bredToDialog.showCalendar = false"
                    v-model="bredToDialog.date"
                  />
                </v-menu>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="!bredToDialog.sire || !bredToDialog.date"
                  @click="editBredTo"
                  color="success"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Color -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="colorDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit Color</h4>
                <v-icon class="buttons" @click="colorDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 80px">
                <v-select
                  :items="colorDialog.colorsAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Color"
                  outlined
                  return-object
                  v-model="colorDialog.color"
                ></v-select>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="
                    !colorDialog.color || colorDialog.color.length == 0
                  "
                  @click="editColor"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Days Bred -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="daysBredDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit Days Bred</h4>
                <v-icon class="buttons" @click="daysBredDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 80px">
                <v-text-field
                  clearable
                  hide-details
                  label="Days Bred"
                  max="300"
                  min="0"
                  outlined
                  type="number"
                  v-model="daysBredDialog.daysBred"
                ></v-text-field>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="daysBredDialog.daysBred == null"
                  @click="editDaysBred"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Group Number -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="groupDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit Group Number</h4>
                <v-icon class="buttons" @click="groupDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 80px">
                <v-text-field
                  clearable
                  hide-details
                  label="Group Number"
                  outlined
                  v-model="groupDialog.groupNumber"
                ></v-text-field>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="
                    !groupDialog.groupNumber ||
                    groupDialog.groupNumber.length == 0
                  "
                  @click="editGroupNumber"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Herd -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="herdDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit Herd</h4>
                <v-icon class="buttons" @click="herdDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 150px">
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="herdDialog.showCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :label="getTranslation('date')"
                      append-icon="mdi-calendar"
                      class="mb-2"
                      hide-details
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="herdDialog.date"
                      v-on:click:append="herdDialog.showCalendar = true"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="herdDialog.showCalendar = false"
                    v-model="herdDialog.date"
                  ></v-date-picker>
                </v-menu>
                <v-select
                  :items="herdDialog.herdsAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  clearable
                  hide-details
                  item-text="name"
                  label="Herd"
                  outlined
                  return-object
                  v-model="herdDialog.herd"
                ></v-select>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="!herdDialog.herd"
                  @click="editHerd"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Pasture or Pen/Location -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="locationDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit {{ getLabelTranslation("pastureSlashPen") }}</h4>
                <v-icon class="buttons" @click="locationDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 80px">
                <v-select
                  :items="locationDialog.locationsAvailable"
                  :label="getLabelTranslation('pastureSlashPen')"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  clearable
                  hide-details
                  item-text="name"
                  outlined
                  return-object
                  v-model="locationDialog.location"
                ></v-select>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="!locationDialog.location"
                  @click="editLocation"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Origin -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="originDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit Origin</h4>
                <v-icon class="buttons" @click="originDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 80px">
                <v-select
                  :items="originDialog.originsAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Origin"
                  outlined
                  return-object
                  v-model="originDialog.origin"
                ></v-select>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="!originDialog.origin"
                  @click="editOrigin"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Parent -->
          <v-dialog
            id="parentDialogForm"
            max-width="550px"
            scrollable
            transition="dialog-transition"
            v-model="parentDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>{{ getTranslation("parentInformation.title") }}</h4>
                <v-icon class="buttons" @click="parentDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="pb-2" style="height: 440px">
                <v-row style="height: 430px" class="pa-3">
                  <v-col class="pa-0 d-flex flex-column" cols="12">
                    <!-- Search EID or visual -->
                    <div class="mb-2">
                      <search-animal
                        :allowedStatuses="[
                          'alive',
                          'cull',
                          'inTransit',
                          'sick',
                          'semen',
                        ]"
                        :resetFields="parentDialog.resetFields"
                        @search-result="getParentResult($event, 'sire')"
                        dense
                        hideTooltip
                      />
                    </div>
                    <!-- Select parent type -->
                    <div class="mb-2 pa-3 pt-0">
                      <v-select
                        :items="parentDialog.options"
                        :menu-props="{ offsetY: true, closeOnClick: true }"
                        dense
                        hide-details
                        item-text="text"
                        label="Type"
                        outlined
                        return-object
                        v-model="parentDialog.type"
                      />
                    </div>
                    <div
                      class="flex-grow-1 d-flex justify-center align-center"
                      v-if="parentDialog.resultSelected"
                    >
                      <v-btn
                        :href="`/animal-details?id=${parentDialog.resultSelected.guid}`"
                        class="h-100"
                        color="primary"
                        link
                        target="_blank"
                        text
                      >
                        <div
                          class="text-center"
                          style="font-size: 1.2rem; width: 428px"
                        >
                          Proposed
                          {{ parentDialog.type ? parentDialog.type.text : "" }}
                          <br />
                          <p class="text-overflow-wrapped ma-0">
                            {{ parentDialog.resultSelected.tagValues }}
                          </p>
                        </div>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="disableSaveButtonOfParentDialog"
                  @click="editParent"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Receiving Ranch -->
          <v-dialog
            max-width="350px"
            scrollable
            transition="dialog-transition"
            v-model="ranchDialog.show"
          >
            <v-card>
              <v-card-title class="px-6 pb-0 d-flex justify-space-between">
                <h4 class="ma-0">Add Receiving Ranch</h4>
                <v-icon @click="ranchDialog.show = false">mdi-close</v-icon>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 300px" class="py-2 px-6">
                <v-text-field
                  class="mb-2"
                  clearable
                  hide-details
                  outlined
                  style="font-size: 1.3rem"
                  v-model="ranchDialog.form.groupNumber"
                >
                  <template #label>
                    <p
                      style="font-size: 1.5rem; font-weight: 'bold'"
                      class="ma-0"
                    >
                      {{ getTranslation("fields.groupNumber") }}
                    </p>
                  </template>
                </v-text-field>
                <div class="mb-2">
                  <v-menu
                    :close-on-content-click="false"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                    v-model="ranchDialog.showCalendar"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        append-icon="mdi-calendar"
                        hide-details
                        outlined
                        readonly
                        style="font-size: 1.3rem"
                        v-bind="attrs"
                        v-model="ranchDialog.form.moveDate"
                        v-on:click:append="ranchDialog.showCalendar = true"
                        v-on="on"
                      >
                        <template #label>
                          <p
                            style="font-size: 1.5rem; font-weight: 'bold'"
                            class="ma-0"
                          >
                            {{ getTranslation("moveDate") }}
                          </p>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      @input="ranchDialog.showCalendar = false"
                      v-model="ranchDialog.form.moveDate"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <v-select
                  :items="getOrganizationItems"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  clearable
                  hide-details
                  item-text="name"
                  outlined
                  return-object
                  style="font-size: 1.3rem"
                  v-model="ranchDialog.form.receivingRanch"
                >
                  <template #label>
                    <p
                      style="font-size: 1.5rem; font-weight: 'bold'"
                      class="ma-0"
                    >
                      {{ getTranslation("fields.receivingRanch") }}
                    </p>
                  </template>
                </v-select>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="disableSaveButtonOfRanchDialog"
                  @click="editRanch"
                  color="success"
                >
                  {{ getLabelTranslation("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Sex -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="sexDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit Sex</h4>
                <v-icon class="buttons" @click="sexDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 80px">
                <v-select
                  :items="sexDialog.sexesAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Sex"
                  outlined
                  return-object
                  v-model="sexDialog.sex"
                ></v-select>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="!sexDialog.sex"
                  @click="editSex"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Status -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="statusDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit Status</h4>
                <v-icon class="buttons" @click="statusDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 210px">
                <v-select
                  :items="statusDialog.statusesAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Status"
                  outlined
                  v-model="statusDialog.status"
                ></v-select>
                <v-text-field
                  class="mb-2"
                  clearable
                  hide-details
                  label="Comment"
                  outlined
                  v-model="statusDialog.comment"
                ></v-text-field>
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="statusDialog.showCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :label="getTranslation('date')"
                      append-icon="mdi-calendar"
                      class="mb-2"
                      hide-details
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="statusDialog.date"
                      v-on:click:append="statusDialog.showCalendar = true"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    value="mm-dd-YYYY"
                    @input="statusDialog.showCalendar = false"
                    v-model="statusDialog.date"
                  ></v-date-picker>
                </v-menu>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="!statusDialog.status || !statusDialog.date"
                  @click="editStatus"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Vitals -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="vitalsDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Edit Vitals</h4>
                <v-icon class="buttons" @click="vitalsDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 450px">
                <!-- Calving Score -->
                <v-select
                  :items="vitalsDialog.calvingScoresAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Calving Score"
                  outlined
                  v-model="vitalsDialog.calvingScore"
                ></v-select>
                <!-- Birth Weight -->
                <v-text-field
                  class="mb-2"
                  clearable
                  hide-details
                  label="Birth Weight"
                  outlined
                  type="number"
                  v-model="vitalsDialog.birthWeight"
                ></v-text-field>
                <!-- Birth Date -->
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="vitalsDialog.showBirthCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      append-icon="mdi-calendar"
                      class="mb-2"
                      clearable
                      hide-details
                      label="Birth Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="vitalsDialog.birthDate"
                      v-on:click:append="vitalsDialog.showBirthCalendar = true"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="vitalsDialog.showBirthCalendar = false"
                    v-model="vitalsDialog.birthDate"
                  ></v-date-picker>
                </v-menu>
                <!-- Death Date -->
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="vitalsDialog.showDeathCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      append-icon="mdi-calendar"
                      class="mb-2"
                      clearable
                      hide-details
                      label="Death Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="vitalsDialog.deathDate"
                      v-on:click:append="vitalsDialog.showDeathCalendar = true"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="vitalsDialog.showDeathCalendar = false"
                    v-model="vitalsDialog.deathDate"
                  ></v-date-picker>
                </v-menu>
                <!-- Origin -->
                <v-select
                  :items="vitalsDialog.originsAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Origin"
                  outlined
                  return-object
                  v-model="vitalsDialog.origin"
                ></v-select>
                <!-- Gender -->
                <v-select
                  :items="vitalsDialog.gendersAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Gender"
                  outlined
                  v-model="vitalsDialog.gender"
                ></v-select>
                <!-- Breed -->
                <v-select
                  :items="vitalsDialog.breedsAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Breed"
                  outlined
                  v-model="vitalsDialog.breed"
                ></v-select>
                <!-- Species -->
                <v-select
                  :items="vitalsDialog.speciesAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Specie"
                  outlined
                  v-model="vitalsDialog.specie"
                ></v-select>
                <!-- Percent Cross -->
                <v-text-field
                  class="mb-2"
                  clearable
                  hide-details
                  label="Percent Cross"
                  outlined
                  v-model="vitalsDialog.percentCross"
                ></v-text-field>
                <!-- Calf Vigor -->
                <v-select
                  :items="vitalsDialog.vigorsAvailable"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Calf Vigor"
                  outlined
                  v-model="vitalsDialog.vigor"
                ></v-select>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn @click="editVitals" color="success">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Record Income -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="recordIncome.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Add Income</h4>
                <v-icon class="buttons" @click="recordIncome.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 210px">
                <v-text-field
                  class="mb-2"
                  clearable
                  hide-details
                  label="Amount"
                  outlined
                  type="number"
                  v-model="recordIncome.amount"
                ></v-text-field>
                <v-text-field
                  class="mb-2"
                  clearable
                  hide-details
                  label="Event Organization"
                  outlined
                  v-model="recordIncome.eventOrganization"
                ></v-text-field>
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="recordIncome.showTransactionDateCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      append-icon="mdi-calendar"
                      class="mb-2"
                      hide-details
                      label="Transaction Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-model="recordIncome.transactionDate"
                      v-on:click:append="recordIncome.transactionDate = true"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="recordIncome.showTransactionDateCalendar = false"
                    v-model="recordIncome.transactionDate"
                  ></v-date-picker>
                </v-menu>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="
                    !recordIncome.eventOrganization || !recordIncome.amount
                  "
                  @click="editRecordIncome"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Activate Newest Tags -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="activateNewestTagsDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Activate Newest Tags</h4>
                <v-icon
                  class="buttons"
                  @click="activateNewestTagsDialog.show = false"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 80px">
                <v-select
                  :items="activateNewestTagsDialog.activateableTagTypes"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Which Tags"
                  outlined
                  v-model="activateNewestTagsDialog.whichTags"
                ></v-select>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="!activateNewestTagsDialog.whichTags"
                  @click="activateNewestTags"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Deactivate Newest Tags -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="deactivateOldestTagsDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Deactivate Newest Tags</h4>
                <v-icon
                  class="buttons"
                  @click="deactivateOldestTagsDialog.show = false"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 80px">
                <v-select
                  :items="deactivateOldestTagsDialog.tagTypes"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  class="mb-2"
                  clearable
                  hide-details
                  item-text="label"
                  item-value="value"
                  label="Which Tags"
                  outlined
                  v-model="deactivateOldestTagsDialog.whichTags"
                ></v-select>
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  :disabled="!deactivateOldestTagsDialog.whichTags"
                  @click="deactivateOldestTags"
                  color="success"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Delete -->
          <v-dialog
            max-width="500px"
            scrollable
            transition="dialog-transition"
            v-model="deleteDialog.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Delete</h4>
                <v-icon class="buttons" @click="deleteDialog.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text class="py-2" style="height: 60px">
                Are you sure you wish to delete
                {{ table.selected.length }} rows?
              </v-card-text>
              <v-divider class="mt-0"></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn @click="deleteRows" color="error">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <!-- Filters -->
        <div>
          <v-dialog
            max-width="350px"
            scrollable
            transition="dialog-transition"
            v-model="toggleFilters.show"
          >
            <v-card>
              <v-card-title class="d-flex justify-space-between">
                <h4>Filters</h4>
                <v-icon class="buttons" @click="toggleFilters.show = false">
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider class="mt-0"></v-divider>
              <v-card-text style="height: 140px" class="py-2 px-6">
                <v-checkbox
                  :key="index"
                  class="mt-0"
                  hide-details
                  v-for="({ name, key }, index) in filterOptions"
                  v-model="toggleFilters.filters[key]"
                >
                  <template #label>
                    <div class="mt-2">
                      {{ name }}
                    </div>
                  </template>
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <!-- Map Dialog -->
        <v-dialog
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          v-model="mapDialog.show"
        >
          <v-card>
            <v-card-title class="px-6 pb-0 d-flex justify-space-between">
              <h4 class="ma-0">{{ getTranslation("Map") }}</h4>
              <v-icon @click="mapDialog.show = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-2 d-flex justify-center align-center">
              <v-progress-circular
                class="flex-grow-1 flex-shrink-1"
                color="teal"
                indeterminate
                size="150"
                style="height: 500px"
                v-if="mapDialog.loading"
                width="13"
              >
              </v-progress-circular>
              <div class="w-100" v-else>
                <p
                  class="ma-0"
                  style="font-size: 24px"
                  v-if="mapDialog.geopointsCollection.length == 0"
                >
                  {{ getTranslation("Geo data is not present") }}
                </p>
                <p
                  class="ma-0"
                  style="font-size: 24px"
                  v-else-if="googleMapIsNotAvailable"
                >
                  {{ getTranslation("Map is not available") }}
                </p>
                <div class="w-100" v-else>
                  <div ref="googleMap" style="width: 100%; height: 810px"></div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Reset Search -->
        <v-dialog
          max-width="500px"
          scrollable
          transition="dialog-transition"
          v-model="resetSearchDialog.show"
        >
          <v-card>
            <v-card-title class="d-flex justify-space-between">
              <h4>Reset Search</h4>
              <v-icon class="buttons" @click="resetSearchDialog.show = false">
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider class="mt-0"></v-divider>
            <v-card-text class="py-2" style="height: 60px">
              Are you sure you wish to reset all searches inputs?
            </v-card-text>
            <v-divider class="mt-0"></v-divider>
            <v-card-actions class="d-flex justify-center">
              <v-btn @click="resetSearchInputs" color="success">{{
                getTranslation("yes")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { isMobile } from "mobile-device-detect";
import { IsSuperAdmin } from "../utils/functions/auth";
import { Loader } from "@googlemaps/js-api-loader";
import { mapActions, mapGetters } from "vuex";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import TranslationMixin from "../mixins/Translations";
import { EventBus } from "../mixins/Config";

export default {
  name: "animals",
  metaInfo: {
    title: "Animals",
  },
  mixins: [TranslationMixin],
  data: () => ({
    Animal: Animal,
    activateNewestTagsDialog: {
      show: false,
      activateableTagTypes: [],
      whichTags: null,
    },
    deactivateOldestTagsDialog: {
      show: false,
      tagTypes: [],
      whichTags: null,
    },
    disableReload: false,
    bredToDialog: {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      showCalendar: false,
      show: false,
      sire: null,
    },
    colorDialog: {
      color: null,
      colorsAvailable: [],
      show: false,
    },
    commentDialog: {
      comment: null,
      show: false,
    },
    daysBredDialog: {
      daysBred: null,
      show: false,
    },
    deleteDialog: {
      show: false,
    },
    forceUpdateDialog: {
      show: false,
    },
    googleMapIsNotAvailable: false,
    groupDialog: {
      groupNumber: null,
      show: false,
    },
    herdDialog: {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      herd: null,
      herdsAvailable: [],
      show: false,
      showCalendar: false,
    },
    initCounter: 0,
    inputs: {
      moveColumn: null,
      selectedGroupNumbers: [],
      selectedHerds: [],
      selectedStatus: "All",
    },
    isMobile,
    itemsPerPage: [5, 10, 15, 50, 100, 200, -1],
    layouts: {
      available: ["default"],
      current: "default",
      dialogs: {
        layout: null,
        newLayout: null,
        previousLayout: null,
        showCreate: false,
        showDelete: false,
        showEdit: false,
      },
    },
    locationDialog: {
      location: null,
      locationsAvailable: [],
      show: false,
    },
    mapDialog: {
      geopointsCollection: [],
      loading: false,
      show: false,
    },
    moveDialog: {
      datePickerStatus: false,
      locationItems: [],
      form: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        location: null,
        reason: null,
        status: null,
      },
      show: false,
      showCalendar: false,
    },
    newAnimalsExist: false,
    originDialog: {
      origin: null,
      originsAvailable: [],
      show: false,
    },
    options: {
      columns: [],
      groupNumbers: [],
      herds: [],
    },
    parentDialog: {
      options: [
        { text: "Dam", value: "dam" },
        { text: "Adopted Dam", value: "adoptDam" },
        { text: "Sire", value: "sire" },
        { text: "Cover Sire", value: "coverSire" },
      ],
      resetFields: false,
      results: [],
      resultSelected: null,
      search: {
        eids: [],
        eidToAnimalShortSummaries: [],
        items: {
          eid: [],
          visual: [],
        },
        type: "eid",
        visuals: [],
        visualToAnimalShortSummaries: [],
      },
      show: false,
      type: null,
    },
    prevTableHeaders: null,
    ranchDialog: {
      form: {
        groupNumber: null,
        moveDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        receivingRanch: null,
      },
      show: false,
      showCalendar: false,
    },
    recordIncome: {
      amount: null,
      eventOrganization: null,
      show: false,
      showTransactionDateCalendar: false,
      transactionDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
    },
    redEditors: ["selectiveDelete", "forceUpdate"],
    reorderedTableHeaders: [],
    resetSearchDialog: {
      show: false,
    },
    selectiveDeleteDialog: {
      form: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        dateType: null,
        groupNumber: null,
        type: null,
      },
      optionsTypes: [
        { value: "Geopoint", key: "geopoints" },
        { value: "Group Number", key: "groupNumber" },
        { value: "Herd", key: "herds" },
        { value: "ID: EID", key: "eid" },
        { value: "ID: Visual", key: "visual" },
        { value: "Pasture/Pen", key: "movements" },
        { value: "TSU/DNA #", key: "dnaNumbers" },
        { value: "Weight", key: "weights" },
      ],
      show: false,
      showCalendar: false,
    },
    sexDialog: {
      sex: null,
      sexesAvailable: [],
      show: false,
    },
    statusDialog: {
      comment: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      show: false,
      showCalendar: false,
      statusesAvailable: [],
      status: null,
    },
    storageListener: null,
    sync: {
      reloadSuggested: false,
    },
    tagDialog: {
      show: false,
      tagsAvailable: [],
      tagTable: null,
      tagValue: null,
      type: null,
      typesAvailable: [],
    },
    title: "animals",
    toggleFilters: {
      filters: {
        groupNumber: false,
        herd: false,
        statusFilter: false,
      },
      show: false,
    },
    touch: {
      event: null,
      start: null,
    },
    vitalsDialog: {
      birthDate: null,
      birthWeight: null,
      breed: null,
      breedsAvailable: [],
      calvingScore: null,
      calvingScoresAvailable: [],
      deathDate: null,
      gender: null,
      gendersAvailable: [],
      origin: null,
      originsAvailable: [],
      percentCross: null,
      show: false,
      showBirthCalendar: false,
      showDeathCalendar: false,
      specie: null,
      speciesAvailable: [],
      vigor: null,
      vigorsAvailable: [],
    },
    herdMeta: null,
    pouches: null,
    refreshTimeout: null,
    table: {
      columnSearches: {},
      data: [],
      dom: null,
      filteredItemsByVuetify: null,
      filteredData: [],
      headers: [],
      hiddenColumns: [],
      loading: false,
      multiRowSelection: false,
      rowsPerPage: 5,
      search: "",
      selectAll: false,
      selected: [],
      tabletMode: false,
      width: null,
    },
  }),
  beforeDestroy: function () {
    EventBus.$off("pouch-changed", () => {});
    this.setRowsCount({ rowsCount: null });
    window.removeEventListener("storage", this.storageListener);
  },
  created: async function () {
    EventBus.$on("pouch-changed", ({ docs }) => {
      this.updateCouchData();
    });
    this.table.loading = true;
    this.herdMeta = this.$herdMeta;
    // FIXME: Find a way to avoid needing to do this
    this.herdMeta.setItems(this.getOrganizationItems, {
      as: "receivingRanches",
      id: "id",
    });

    this.pouches = this.herdMeta.pouches;
    this.storageListener = async (e) => {
      if (
        e.storageArea == localStorage &&
        e.key === "pouch-changes-counter" &&
        e.newValue != e.oldValue
      ) {
        if (this.refreshTimeout) {
          this.sync.reloadSuggested = false;
          clearTimeout(this.refreshTimeout);
        }
        this.refreshTimeout = setTimeout(async () => {
          this.sync.reloadSuggested = true;
        }, 3 * 1000);
      }
    };
    window.addEventListener("storage", this.storageListener);
    this.init();
  },
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
      getRowsCount: "Rows/getRowsCount",
    }),
    filterOptions: function () {
      return [
        { name: this.getTranslation("Group Number"), key: "groupNumber" },
        { name: this.getTranslation("Herd"), key: "herd" },
        { name: this.getTranslation("Status"), key: "statusFilter" },
      ];
    },
    isSuperAdmin: function () {
      return IsSuperAdmin();
    },
    sortedTableHeaders: function () {
      return (
        // run map so sort does not affect tableHeaders array directly
        [...this.tableHeaders].sort((a, b) => a.text.localeCompare(b.text))
      );
    },
    statusItems: function () {
      return this.getEnumOptions("statuses").map((item) => {
        return {
          key: item.value.toLowerCase(),
          name: item.label,
        };
      });
    },
    statusFilterOptions: function () {
      return [
        { label: this.getTranslation("alive"), value: "Alive" },
        { label: this.getTranslation("Alive or Cull"), value: "Alive,Cull" },
        { label: this.getTranslation("Cull"), value: "Cull" },
        { label: this.getTranslation("Not Alive"), value: "NotAlive" },
        {
          label: this.getTranslation("Not Alive and not Cull"),
          value: "NotAlive,NotCull",
        },
        { label: this.getTranslation("All"), value: "All" },
      ];
    },
    disableSaveButtonOfParentDialog: function () {
      if (!this.parentDialog.resultSelected) return true;
      if (!this.parentDialog.type) return true;
      if (
        this.parentDialog.results.length == 1 ||
        this.parentDialog.resultSelected
      )
        return false;
      return false;
    },
    disableSaveButtonOfRanchDialog: function () {
      return (
        !(
          this.ranchDialog.form.groupNumber &&
          this.ranchDialog.form.receivingRanch &&
          this.ranchDialog.form.moveDate
        ) || this.ranchDialog.form.groupNumber.trim() == ""
      );
    },
    tableHeaders: function () {
      return [
        {
          class: "rowNumber",
          draggable: false,
          text: this.getTranslation("Row"),
          value: "rowNumber",
          visible: true,
        },
        {
          class: "visualTagValues",
          draggable: true,
          searchable: true,
          text: this.getTranslation("visualTagValues"),
          value: "visualTagValues",
          visible: true,
        },
        {
          class: "visualTagColors",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Visual Colors"),
          value: "visualTagColors",
          visible: false,
        },
        {
          class: "id",
          draggable: true,
          searchable: false,
          sortable: false,
          text: this.getTranslation("details"),
          value: "id",
          visible: true,
        },
        {
          class: "newTag",
          draggable: true,
          searchable: true,
          text: this.getTranslation("New Tag"),
          value: "newTag",
          visible: true,
        },
        {
          class: "birthDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("birthDate"),
          value: "birthDate",
          visible: true,
        },
        {
          class: "age",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Age"),
          value: "age",
          visible: true,
        },
        {
          class: "predictedCalvingDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("predictedCalvingDate"),
          value: "predictedCalvingDate",
          visible: true,
        },
        {
          class: "flex",
          draggable: true,
          searchable: true,
          text: "Flex",
          value: "flexTag",
          visible: true,
        },
        {
          class: "pregCheckTime",
          draggable: true,
          searchable: true,
          text: this.getTranslation("pregCheckTime"),
          value: "pregCheckTime",
          visible: true,
        },
        {
          class: "cycle",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Cycle"),
          value: "cycle",
          visible: true,
        },
        {
          class: "daysBred",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Days Bred"),
          value: "daysBred",
          visible: true,
        },
        {
          class: "status",
          draggable: true,
          searchable: true,
          text: this.getTranslation("status"),
          value: "status",
          visible: true,
        },
        {
          class: "sex",
          draggable: true,
          searchable: true,
          text: this.getTranslation("sex"),
          value: "sex",
          visible: true,
        },
        {
          class: "carcassAge",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Carcass Age"),
          value: "carcassAge",
          visible: true,
        },
        {
          class: "carcassType",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Carcass Type"),
          value: "carcassType",
          visible: true,
        },
        {
          class: "carcId",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Carc ID"),
          value: "carcId",
          visible: true,
        },
        {
          class: "hotScaleWeight",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Hot Scale Weight"),
          value: "hotScaleWeight",
          visible: true,
        },
        {
          class: "killDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Kill Date"),
          value: "killDate",
          visible: true,
        },
        {
          class: "killLot",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Kill Lot"),
          value: "killLot",
          visible: true,
        },
        {
          class: "od",
          draggable: true,
          searchable: true,
          text: "OD",
          value: "od",
          visible: true,
        },
        {
          class: "od2",
          draggable: true,
          searchable: true,
          text: "OD2",
          value: "od2",
          visible: true,
        },
        {
          class: "plant",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Plant"),
          value: "plant",
          visible: true,
        },
        {
          class: "qualityGrade",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Quality Grade"),
          value: "qualityGrade",
          visible: true,
        },
        {
          class: "qualityGrade2",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Quality Grade 2"),
          value: "qualityGrade2",
          visible: true,
        },
        {
          class: "yieldGrade",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Yield Grade"),
          value: "yieldGrade",
          visible: true,
        },
        {
          class: "location",
          draggable: true,
          searchable: true,
          text: this.getLabelTranslation("pastureSlashPen"),
          value: "location",
          visible: true,
        },
        {
          class: "birthWeight",
          draggable: true,
          searchable: true,
          text: this.getTranslation("birthWeight"),
          value: "birthWeight",
          visible: true,
        },
        {
          class: "breed",
          draggable: true,
          searchable: true,
          text: this.getTranslation("breed"),
          value: "breed",
          visible: true,
        },
        {
          class: "color",
          draggable: true,
          searchable: true,
          text: this.getTranslation("color"),
          value: "color",
          visible: true,
        },
        {
          class: "currentWeight",
          draggable: true,
          searchable: true,
          text: this.getTranslation("currentWeight"),
          value: "currentWeight",
          visible: true,
        },
        {
          class: "currentWeightDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("currentWeightDate"),
          value: "currentWeightDate",
          visible: true,
        },
        {
          class: "groupNumber",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Group"),
          value: "groupNumber",
          visible: true,
        },
        {
          class: "herd",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Herd"),
          value: "herd",
          visible: true,
        },
        {
          class: "EIDtagValues",
          draggable: true,
          searchable: true,
          text: this.getTranslation("EIDs"),
          value: "EIDtagValues",
          visible: true,
        },
        {
          class: "EIDcolors",
          draggable: true,
          searchable: true,
          text: this.getTranslation("EIDcolors"),
          value: "EIDcolors",
          visible: false,
        },
        {
          class: "nuesTagValues",
          draggable: true,
          searchable: true,
          text: this.getTranslation("nuesTagValues"),
          value: "nuesTagValues",
          visible: true,
        },
        {
          class: "associationTagValues",
          draggable: true,
          searchable: true,
          text: this.getTranslation("associationTagValues"),
          value: "associationTagValues",
          visible: false,
        },
        {
          class: "brandTagValues",
          draggable: true,
          searchable: true,
          text: this.getTranslation("brandTagValues"),
          value: "brandTagValues",
          visible: false,
        },
        {
          class: "brisketTagValues",
          draggable: true,
          searchable: true,
          text: this.getTranslation("brisketTagValues"),
          value: "brisketTagValues",
          visible: false,
        },
        {
          class: "tattooTagValues",
          draggable: true,
          searchable: true,
          text: this.getTranslation("tattooTagValues"),
          value: "tattooTagValues",
          visible: false,
        },
        {
          class: "backtagValues",
          draggable: true,
          searchable: true,
          text: this.getTranslation("backtagValues"),
          value: "backtagValues",
          visible: false,
        },
        {
          class: "backtagColors",
          draggable: true,
          searchable: true,
          text: this.getTranslation("backtagColors"),
          value: "backtagColors",
          visible: false,
        },
        {
          class: "tagLot",
          draggable: true,
          searchable: true,
          text: this.getTranslation("tagLot"),
          value: "tagLot",
          visible: false,
        },
        {
          class: "description",
          draggable: true,
          searchable: true,
          text: this.getTranslation("description"),
          value: "description",
          visible: true,
        },
        {
          class: "alerts",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Alerts"),
          value: "alerts",
          visible: true,
        },
        {
          class: "clearanceDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("clearanceDate"),
          value: "clearanceDate",
          visible: false,
        },
        {
          class: "clearanceTimeFrame",
          draggable: true,
          searchable: true,
          text: this.getTranslation("clearanceTimeFrame"),
          value: "clearanceTimeFrame",
          visible: false,
        },
        {
          class: "receivingRanch",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Receiving Ranch"),
          value: "receivingRanch",
          visible: true,
        },
        {
          class: "daysSinceLastMove",
          draggable: true,
          searchable: true,
          text: this.getTranslation("daysSinceLastMove"),
          value: "daysSinceLastMove",
          visible: false,
        },
        {
          class: "dnaNumber",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Dna Number"),
          value: "dnaNumber",
          visible: true,
        },
        {
          class: "processingDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("processingDate"),
          value: "processingDate",
          visible: false,
        },
        {
          class: "processingCost",
          draggable: true,
          searchable: true,
          text: this.getTranslation("processingCost"),
          value: "processingCost",
          visible: false,
        },
        {
          class: "doctoringCost",
          draggable: true,
          searchable: true,
          text: this.getTranslation("doctoringCost"),
          value: "doctoringCost",
          visible: false,
        },
        {
          class: "aiSireTags",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Bred To"),
          value: "aiSireTags",
          visible: false,
        },
        {
          class: "aiStatusId",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Bred Status"),
          value: "aiStatusId",
          visible: false,
        },
        {
          class: "pregCheckResult",
          draggable: true,
          searchable: true,
          text: this.getTranslation("pregResult"),
          value: "pregCheckResult",
          visible: false,
        },
        {
          class: "pregGroup",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Preg Group"),
          value: "pregGroup",
          visible: false,
        },
        {
          class: "pregMethod",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Preg Method"),
          value: "pregMethod",
          visible: false,
        },
        {
          class: "pregTechnician",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Preg Technician"),
          value: "pregTechnician",
          visible: false,
        },
        {
          class: "pregCheckStatus",
          draggable: true,
          searchable: true,
          text: this.getTranslation("pregCheckStatus"),
          value: "pregCheckStatus",
          visible: false,
        },
        {
          class: "cycle",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Cycle"),
          value: "cycle",
          visible: true,
        },
        {
          class: "bcs",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Bcs"),
          value: "bcs",
          visible: true,
        },
        {
          class: "bullTurninDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Bull Turnin Date"),
          value: "bullTurninDate",
          visible: true,
        },
        {
          class: "fetalSex",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Fetal Sex"),
          value: "fetalSex",
          visible: false,
        },
        {
          class: "mouthScore",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Mouth Score"),
          value: "mouthScore",
          visible: true,
        },
        {
          class: "firstWeight",
          draggable: true,
          searchable: true,
          text: this.getTranslation("firstWeight"),
          value: "firstWeight",
          visible: true,
        },
        {
          class: "firstWeightDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("firstWeightDate"),
          value: "firstWeightDate",
          visible: true,
        },
        {
          class: "daysBetweenFirstAndLastWeight",
          draggable: true,
          searchable: true,
          text: this.getTranslation("daysBetweenFirstAndLastWeight"),
          value: "daysBetweenFirstAndLastWeight",
          visible: true,
        },
        {
          class: "headDays",
          draggable: true,
          searchable: true,
          text: this.getTranslation("headDays"),
          value: "headDays",
          visible: false,
        },
        {
          class: "adgTotal",
          draggable: true,
          searchable: true,
          text: this.getTranslation("adgTotal"),
          value: "adgTotal",
          visible: true,
        },
        {
          class: "adg2",
          draggable: true,
          searchable: true,
          text: this.getTranslation("adg2"),
          value: "adg2",
          visible: true,
        },
        {
          class: "purchasePrice",
          draggable: true,
          searchable: true,
          text: this.getTranslation("purchasePrice"),
          value: "purchasePrice",
          visible: false,
        },
        {
          class: "totalEventIncome",
          draggable: true,
          searchable: true,
          text: this.getTranslation("totalEventIncome"),
          value: "totalEventIncome",
          visible: false,
        },
        {
          class: "freightCost",
          draggable: true,
          searchable: true,
          text: this.getTranslation("freightCost"),
          value: "freightCost",
          visible: false,
        },
        {
          class: "purchaseDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("purchaseDate"),
          value: "purchaseDate",
          visible: false,
        },
        {
          class: "purchaseCommissionRate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("purchaseCommissionRate"),
          value: "purchaseCommissionRate",
          visible: false,
        },
        {
          class: "damTags",
          draggable: true,
          searchable: true,
          text: this.getTranslation("dam"),
          value: "damTags",
          visible: true,
        },
        {
          class: "adoptDamTags",
          draggable: true,
          searchable: true,
          text: this.getTranslation("adoptDam"),
          value: "adoptDamTags",
          visible: true,
        },
        {
          class: "sireTags",
          draggable: true,
          searchable: true,
          text: this.getTranslation("sire"),
          value: "sireTags",
          visible: true,
        },
        {
          class: "coverSireTags",
          draggable: true,
          searchable: true,
          text: this.getTranslation("coverSire"),
          value: "coverSireTags",
          visible: false,
        },
        {
          class: "deathDate",
          draggable: true,
          searchable: true,
          text: this.getTranslation("deathDate"),
          value: "deathDate",
          visible: false,
        },
        {
          class: "birthCalvingEase",
          draggable: true,
          searchable: true,
          text: this.getTranslation("birthCalvingEase"),
          value: "birthCalvingEase",
          visible: true,
        },
        {
          class: "birthVisual",
          draggable: true,
          searchable: true,
          text: this.getTranslation("birthVisual"),
          value: "birthVisual",
          visible: false,
        },
        {
          class: "gender",
          draggable: true,
          searchable: true,
          text: this.getTranslation("gender"),
          value: "gender",
          visible: true,
        },
        {
          class: "vigor",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Vigor"),
          value: "vigor",
          visible: true,
        },
        {
          class: "milk",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Milk"),
          value: "milk",
          visible: false,
        },
        {
          class: "hoof",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Hoof"),
          value: "hoof",
          visible: false,
        },
        {
          class: "rectalTemperature",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Rectal Temperature"),
          value: "rectalTemperature",
          visible: false,
        },
        {
          class: "pelvicMeasure",
          draggable: true,
          searchable: true,
          text: this.getTranslation("pelvicMeasure"),
          value: "pelvicMeasure",
          visible: true,
        },
        {
          class: "bullTest",
          draggable: true,
          searchable: true,
          text: this.getTranslation("bullTest"),
          value: "bullTest",
          visible: true,
        },
        {
          class: "bullTestResult",
          draggable: true,
          searchable: true,
          text: this.getTranslation("bullTestResult"),
          value: "bullTestResult",
          visible: true,
        },
        {
          class: "bullTestMethod",
          draggable: true,
          searchable: true,
          text: this.getTranslation("bullTestMethod"),
          value: "bullTestMethod",
          visible: true,
        },
        {
          class: "origin",
          draggable: true,
          searchable: true,
          text: this.getTranslation("origin"),
          value: "origin",
          visible: true,
        },
        {
          class: "percentCross",
          draggable: true,
          searchable: true,
          text: this.getTranslation("percentCross"),
          value: "percentCross",
          visible: false,
        },
        {
          class: "species",
          draggable: true,
          searchable: true,
          text: this.getTranslation("species"),
          value: "species",
          visible: false,
        },
        {
          class: "comment",
          draggable: true,
          searchable: true,
          text: this.getTranslation("comment"),
          value: "comment",
          visible: true,
        },
        {
          class: "lastUpdated",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Updated"),
          value: "lastUpdated",
          visible: true,
        },
        {
          class: "animalCreatedOn",
          draggable: true,
          searchable: true,
          text: this.getTranslation("Creation Date"),
          value: "animalCreatedOn",
          visible: false,
        },
      ];
    },
    editors: function () {
      return [
        {
          disabled: this.table.selected.length == 0,
          name: "forceUpdate",
          showDialog: () => (this.forceUpdateDialog.show = true),
          text: "Force Update",
        },
        {
          disabled: this.table.selected.length == 0,
          name: "selectiveDelete",
          showDialog: () => (this.selectiveDeleteDialog.show = true),
          text: "Selective Delete By Date",
        },
        {
          disabled: this.table.selected.length != 2,
          name: "selectiveMerge",
          showDialog: () =>
            this.$router.push(
              `/review-duplicate-animals?id=${this.table.selected[0].guid}&id2=${this.table.selected[1].guid}`
            ),
          text: this.getTranslation("Merge Duplicate"),
        },
        {
          disabled: this.table.selected.length === 0,
          showDialog: () => (this.bredToDialog.show = true),
          text: this.getTranslation("Bred To"),
        },
        {
          text: "Color",
          showDialog: () => {
            this.colorDialog.show = true;
            this.colorDialog.color = null;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Comment",
          showDialog: () => {
            this.commentDialog.show = true;
            this.commentDialog.comment = null;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          disabled: this.table.selected.length == 0,
          name: "daysBred",
          showDialog: () => (this.daysBredDialog.show = true),
          text: "Days Bred",
        },
        {
          text: "Group Number",
          showDialog: () => {
            this.groupDialog.show = true;
            this.groupDialog.groupNumber = null;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Herd",
          showDialog: () => {
            this.herdDialog.show = true;
            this.herdDialog.herd = null;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: this.getLabelTranslation("pastureSlashPen"),
          showDialog: () => {
            this.locationDialog.show = true;
            this.locationDialog.location = null;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Origin",
          showDialog: () => {
            this.originDialog.show = true;
            this.originDialog.origin = null;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Parent",
          showDialog: async () => {
            this.resetParentDialogFields();
            await this.filterEidsAndVisuals();
            this.parentDialog.show = true;
            this.parentDialog.resetFields = !this.parentDialog.resetFields;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Receiving Ranch",
          showDialog: () => {
            this.resetRanchDialogFields();
            this.ranchDialog.show = true;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Sex",
          showDialog: () => {
            this.sexDialog.sex = null;
            this.sexDialog.show = true;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Status",
          showDialog: () => {
            this.statusDialog.show = true;
            this.statusDialog.comment = null;
            this.statusDialog.status = null;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Tag",
          showDialog: () => {
            this.tagDialog.show = true;
            this.tagDialog.tagTable = null;
            this.tagDialog.tagValue = null;
            this.tagDialog.type = null;
          },
          disabled:
            this.table.selected.length == 0 || this.table.selected.length > 1,
        },
        {
          text: "Vitals",
          showDialog: () => {
            this.vitalsDialog.show = true;
            this.vitalsDialog.calvingScore =
              this.vitalsDialog.birthWeight =
              this.vitalsDialog.birthDate =
              this.vitalsDialog.deathDate =
              this.vitalsDialog.origin =
              this.vitalsDialog.gender =
              this.vitalsDialog.breed =
              this.vitalsDialog.specie =
              this.vitalsDialog.percentCross =
              this.vitalsDialog.vigor =
                null;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Record Income",
          showDialog: () => {
            this.recordIncome.show = true;
            this.recordIncome.amount = null;
            this.recordIncome.eventOrganization = null;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Activate Newest Tags",
          showDialog: () => {
            this.activateNewestTagsDialog.show = true;
            this.activateNewestTagsDialog.whichTags =
              this.activateNewestTagsDialog.activateableTagTypes[0].value;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          text: "Deactivate Oldest Tags",
          showDialog: () => {
            this.deactivateOldestTagsDialog.show = true;
            this.deactivateOldestTagsDialog.whichTags =
              this.deactivateOldestTagsDialog.tagTypes[0].value;
          },
          disabled: this.table.selected.length == 0,
        },
        {
          disabled: this.table.selected.length == 0,
          showDialog: () => (this.deleteDialog.show = true),
          text: "Delete",
        },
      ];
    },
    invisibleHeaders: function () {
      return this.reorderedTableHeaders.filter(({ visible }) => !visible);
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler: function (val) {
        localStorage.setItem(
          this.getLayoutSettingName(this.layouts.current, "inputs"),
          JSON.stringify(val)
        );
      },
    },
    "layouts.available": function (val) {
      localStorage.setItem(
        this.getLayoutsSettings("available"),
        JSON.stringify(val)
      );
    },
    "layouts.current": {
      handler: async function (val) {
        this.table.loading = true;
        localStorage.setItem(this.getLayoutsSettings("current"), val);
        this.getPrevLayoutConfig();
        await this.makeTable();
        this.toggleTabletMode();
        this.table.loading = false;
      },
    },
    "parentDialog.search.type": "resetParentDialogFields",
    "table.columnSearches": {
      deep: true,
      handler: function (val) {
        localStorage.setItem(
          this.getLayoutSettingName(this.layouts.current, "columnSearches"),
          JSON.stringify(val)
        );
      },
    },
    "table.rowsPerPage": function (val) {
      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "rowsPerPage"),
        JSON.stringify(val)
      );
    },
    "table.search": function name(val) {
      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "globalSearch"),
        JSON.stringify(val)
      );
    },
    "table.tabletMode": {
      handler: function (val) {
        localStorage.setItem(
          this.getLayoutSettingName(this.layouts.current, "tabletMode"),
          JSON.stringify(val)
        );
      },
    },
    "sync.reloadSuggested": {
      /// reload table automatically if user does not have a dialog open, otherwise post a message
      handler: function (val) {
        if (!val) return;
        this.updateCouchData();
      },
    },
  },
  methods: {
    ...mapActions({
      setRowsCount: "Rows/setRowsCount",
    }),
    updateCouchData: function () {
      if (
        // by registering all dialogs here, we prevent the UI from automatically reloading while the user is filling in a form.
        this.activateNewestTagsDialog.show ||
        this.colorDialog.show ||
        this.commentDialog.show ||
        this.daysBredDialog.show ||
        this.deactivateOldestTagsDialog.show ||
        this.deleteDialog.show ||
        this.forceUpdateDialog.show ||
        this.groupDialog.show ||
        this.herdDialog.show ||
        this.layouts.dialogs.showCreate ||
        this.layouts.dialogs.showDelete ||
        this.layouts.dialogs.showEdit ||
        this.locationDialog.show ||
        this.mapDialog.show ||
        this.moveDialog.show ||
        this.originDialog.show ||
        this.parentDialog.show ||
        this.ranchDialog.show ||
        this.resetSearchDialog.show ||
        this.selectiveDeleteDialog.show ||
        this.sexDialog.show ||
        this.statusDialog.show ||
        this.tagDialog.show ||
        this.toggleFilters.show ||
        this.vitalsDialog.show
      ) {
        // show message
        this.$notify({
          group: "forms",
          ignoreDuplicates: true,
          text: "Reload is suggested. Your database received an update. Auto-reload is not possible because you have a dialog box open.",
          title: "Update available",
          type: "info",
        });

        return;
      }

      this.init();
      this.sync.reloadSuggested = false;
    },
    getSireResult: function ({ animal }) {
      if (animal) this.bredToDialog.sire = animal;
      else this.bredToDialog.sire = null;
    },
    sortVisualTag: function (items, index, isDesc) {
      if (index == null) return items;

      if (
        index[0] !== "birthWeight" &&
        index[0] !== "currentWeight" &&
        index[0] !== "daysBred" &&
        index[0] !== "firstWeight" &&
        index[0] !== "visualTagValues" &&
        index[0] !== "weight"
      ) {
        items.sort((a, b) =>
          (a[index[0]] + "").localeCompare(b[index[0]] + "")
        );

        if (isDesc[0]) items.reverse();
        return items;
      }
      const largestDigitLength = items.reduce((reduction, item) => {
        const matches = item.visualTagValues.match(/\d+/g);
        (matches || []).forEach((match) => {
          if (reduction < match.length) {
            reduction = match.length;
          }
        });

        return reduction;
      }, 0);

      if (largestDigitLength === 0)
        items.sort(({ visualTagValues: a }, { visualTagValues: b }) =>
          a.localeCompare(b)
        );
      else {
        items.forEach((item) => {
          item.visualHumanSortFriendly =
            this.$utils.makeVisualHumanSortFriendly(
              largestDigitLength,
              item.visualTagValues
            );
        });

        items.sort(
          ({ visualHumanSortFriendly: a }, { visualHumanSortFriendly: b }) =>
            a.localeCompare(b)
        );
      }

      if (isDesc[0]) items.reverse();

      return items;
    },
    init: async function (force) {
      this.initCounter++;
      if (this.initCounter == 1 || force) {
        // Wait 1 minute to stop re-updating all the calls
        // that causes slow latency on the page
        // This will make sure that the init function will not be
        // re-called on every couch db update and will wait to be completed
        // the promise
        setTimeout(() => {
          this.initCounter = 0;
        }, 1 * 1000 * 60);
        await this.herdMeta.reload();
        this.disableReload = true;
        this.table.loading = true;
        this.herdMeta.loaded.always(() => {
          this.pouches = this.herdMeta.pouches;
          this._detectNewAnimalsWithMissingSummaries(this.herdMeta, true).then(
            async () => {
              this.getPrevLayoutConfig();
              await this.makeTable();
              this.toggleTabletMode();
              this.table.loading = false;
              this.disableReload = false;
            }
          );
        });
      }
    },
    createLayout: function () {
      const layout = this.layouts.dialogs.layout.trim().toLowerCase();
      this.layouts.available.push(layout);
      this.layouts.current = layout;

      this.storeLayoutSettings();

      this.layouts.dialogs.showCreate = false;
      this.layouts.dialogs.layout = null;
    },
    deleteLayout: function () {
      const layout = this.layouts.dialogs.layout;
      const idx = this.layouts.available.findIndex((item) => item == layout);
      this.layouts.available.splice(idx, 1);
      if (this.layouts.current == layout)
        this.layouts.current = this.layouts.available[0];
      localStorage.setItem(
        this.getLayoutsSettings("available"),
        JSON.stringify(this.layouts.available)
      );
      this.removeLayoutSettings(layout);
      this.layouts.dialogs.showDelete = false;
    },
    editLayout: function () {
      const prevLayout = this.layouts.dialogs.previousLayout
        .trim()
        .toLowerCase();
      const newLayout = this.layouts.dialogs.newLayout.trim().toLowerCase();
      const idx = this.layouts.available.findIndex(
        (item) => item == prevLayout
      );

      this.layouts.available.splice(idx, 1, newLayout);
      localStorage.setItem(
        this.getLayoutsSettings("available"),
        JSON.stringify(this.layouts.available)
      );
      this.removeLayoutSettings(prevLayout);
      this.layouts.current = this.layouts.available[idx];
      this.storeLayoutSettings();
      this.layouts.dialogs.showEdit = false;
    },
    getLayoutSettingName: function (layout, type) {
      return this.orgUserScoping("layout." + type + "_" + layout);
    },
    getLayoutsSettings: function (type) {
      return this.orgUserScoping("layouts." + type);
    },
    // for use with localStorage
    orgUserScoping: function (type) {
      return [this.title, type, this.$organizationID, this.$userID].join("_");
    },
    getPrevLayoutConfig: function () {
      // Layouts created
      const available = JSON.parse(
        localStorage.getItem(this.getLayoutsSettings("available"))
      );
      if (available) this.layouts.available = available;

      // Last layout used
      const current = localStorage.getItem(this.getLayoutsSettings("current"));
      this.layouts.current = current || "default";

      // Previous Values of filters
      const prevFiltersValues = JSON.parse(
        localStorage.getItem(
          this.getLayoutSettingName(this.layouts.current, "inputs")
        )
      );
      this.inputs = prevFiltersValues || {
        selectedGroupNumbers: [],
        selectedHerds: [],
        selectedStatus: "All",
      };

      // Filters visibility
      const defaultVisibility = {
        groupNumber: false,
        herd: false,
        statusFilter: false,
      };
      Object.keys(this.inputs).forEach((key) => {
        if (key.includes("selected")) {
          if (this.inputs[key].length && this.inputs[key] != "All") {
            const input = key.substring(8, key.length - 1);
            Object.keys(defaultVisibility).forEach((prop) => {
              if (prop.toLowerCase().includes(input.toLowerCase()))
                defaultVisibility[prop] = true;
            });
          }
        }
      });
      this.toggleFilters.filters = defaultVisibility || {
        groupNumber: false,
        herd: false,
        statusFilter: this.inputs.selectedStatus != "All",
      };

      // Columns visibility
      const prevColsConfig = JSON.parse(
        localStorage.getItem(
          this.getLayoutSettingName(this.layouts.current, "columns")
        )
      );
      // Search for a missing headers inside tableHeaders
      const missingHeaders = this.tableHeaders.filter(
        (header) =>
          (prevColsConfig || []).filter((col) => col.value === header.value)
            .length === 0
      );

      this.prevTableHeaders =
        prevColsConfig && prevColsConfig.concat(missingHeaders);

      // Tablet Mode
      const tabletMode = JSON.parse(
        localStorage.getItem(
          this.getLayoutSettingName(this.layouts.current, "tabletMode")
        )
      );
      this.table.tabletMode = tabletMode || false;

      // Global Search
      const search = JSON.parse(
        localStorage.getItem(
          this.getLayoutSettingName(this.layouts.current, "globalSearch")
        )
      );
      this.table.search = search || null;

      // Rows Per Page
      const prevRowsPerPage = JSON.parse(
        localStorage.getItem(
          this.getLayoutSettingName(this.layouts.current, "rowsPerPage")
        )
      );
      this.table.rowsPerPage = prevRowsPerPage || this.itemsPerPage[0];
    },
    prepareTableHeaders: async function () {
      this.table.columnSearches =
        JSON.parse(
          localStorage.getItem(
            this.getLayoutSettingName(this.layouts.current, "columnSearches")
          )
        ) || (await this.getSearchableHeaders());
      const self = this;
      this.table.headers = (this.prevTableHeaders || this.tableHeaders).map(
        (header) => {
          if (header.searchable) {
            header.filter = function (value) {
              const input = self.table.columnSearches[header.value];
              if (!value) value = "";
              if (typeof value == "number") value = value.toString();
              if (!input || input.trim().length == 0) return true;
              return value.toLowerCase().includes(input.trim().toLowerCase());
            };
          }
          return header;
        }
      );
      this.reorderedTableHeaders = this.table.headers;
      this.table.headers = this.table.headers.filter(
        ({ visible }) => !!visible
      );
    },
    removeLayoutSettings: function (layout) {
      localStorage.removeItem(this.getLayoutSettingName(layout, "columns"));
      localStorage.removeItem(
        this.getLayoutSettingName(layout, "columnSearches")
      );
      localStorage.removeItem(
        this.getLayoutSettingName(layout, "globalSearch")
      );
      localStorage.removeItem(this.getLayoutSettingName(layout, "inputs"));
      localStorage.removeItem(this.getLayoutSettingName(layout, "rowsPerPage"));
      localStorage.removeItem(this.getLayoutSettingName(layout, "tabletMode"));
    },
    storeLayoutSettings: function () {
      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "columns"),
        JSON.stringify(this.reorderedTableHeaders)
      );
      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "columnSearches"),
        JSON.stringify(this.table.columnSearches)
      );
      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "globalSearch"),
        JSON.stringify(this.table.search)
      );
      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "inputs"),
        JSON.stringify(this.inputs)
      );
      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "rowsPerPage"),
        JSON.stringify(this.table.rowsPerPage)
      );
      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "tabletMode"),
        JSON.stringify(this.table.tabletMode)
      );
    },
    openEditLayoutDialog: function (layout) {
      this.layouts.dialogs.showEdit = true;
      this.layouts.dialogs.previousLayout = this.layouts.dialogs.newLayout =
        layout;
    },
    openDeleteLayoutDialog: function (layout) {
      this.layouts.dialogs.showDelete = true;
      this.layouts.dialogs.layout = layout;
    },
    makeTable: async function () {
      try {
        this.table.dom = this.$refs.animalsTable.$el.children[1].children[0];
        await this.filterData();
      } catch (e) {
        console.info(e);
        this.$notify({
          group: "forms",
          text: "Error getting animals",
          title: "Error",
          type: "error",
        });
        return;
      }
      this.prepareTableHeaders();
      this.table.loading = false;
      await this.prepareFilters();
      await this.prepareEditors();

      if (!this.$utils.isWhitespace(this.table.search)) this.runGlobalSearch();
    },
    _detectNewAnimalsWithMissingSummaries: async function (
      herdMeta,
      forceBuildSummaries
    ) {
      try {
        this.table.loading = true;
        const message = forceBuildSummaries
          ? "Double-checking."
          : "Searching for new animals. This might take a long time if it is the first time or if a lot of new animals were recently added.";
        this.$notify({
          group: "forms",
          ignoreDuplicates: true,
          text: message,
          title: "Info",
          type: "info",
        });
        const results = await this.pouches.organization.query(
          "local_views/animalsWithoutSummary"
        );
        results.rows = results.rows.filter(({ value }) => !value.deleted);
        if (!results.rows.length) {
          this.newAnimalsExist = false;

          this.$notify({
            group: "forms",
            ignoreDuplicates: true,
            text: "No new animals.",
            title: "Success",
            type: "success",
          });
          return;
        }

        if (!forceBuildSummaries) {
          this.newAnimalsExist = true;
          return;
        }

        this.$notify({
          group: "forms",
          ignoreDuplicates: true,
          text: "Updating summaries. This might take a long time if it is the first time.",
          title: "Info",
          type: "info",
        });
        const saves = results.rows.map(({ key }) =>
          this.pouches.organization.get(key).then((doc) => {
            return new Animal(key, herdMeta, doc, this.$userID).save(true);
          })
        );
        Promise.all(saves).then(() => {
          this.$notify({
            group: "forms",
            text: "Done summarizing data.",
            title: "Success",
            type: "success",
          });
          this.newAnimalsExist = false;
          console.log("Updated");
        });
      } catch (error) {
        this.$notify({
          group: "forms",
          ignoreDuplicates: true,
          text: error.message,
          title: "Error",
          type: "error",
        });
      } finally {
        this.table.loading = false;
      }
    },
    resetRowsPerPage: function () {
      this.table.rowsPerPage = this.itemsPerPage[0];
    },
    setItemsLengthToBadge: function (paginationObject) {
      this.setRowsCount({
        rowsCount: this.getItemsLength(paginationObject),
      });
    },
    getItemsLength: function (paginationObject) {
      return paginationObject.itemsLength;
    },
    toggleSelectAll: function () {
      if (this.table.selectAll)
        this.table.selected = this.table.filteredItemsByVuetify;
      else this.table.selected = [];
    },
    captureCtrlKey: function (e) {
      if (e.keyCode === 17) this.table.multiRowSelection = true;
      else this.table.multiRowSelection = false;
    },
    reorderColumns: function (e) {
      const value_from = e.item.id;
      const col_from = this.table.headers.find(
        ({ value }) => value == value_from
      );
      const from_idx = this.table.headers.findIndex(
        ({ value }) => value == value_from
      );
      const to_idx = e.newDraggableIndex + 1;

      this.table.headers.splice(from_idx, 1);
      this.table.headers.splice(to_idx, 0, col_from);

      const col_from_reordered = this.reorderedTableHeaders.find(
        ({ value }) => value == value_from
      );

      const from_idx_reordered = this.reorderedTableHeaders.findIndex(
        ({ value }) => value == value_from
      );

      this.reorderedTableHeaders.splice(from_idx_reordered, 1);
      this.reorderedTableHeaders.splice(to_idx, 0, col_from_reordered);
      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "columns"),
        JSON.stringify(this.reorderedTableHeaders)
      );
    },
    runGlobalSearch: function () {
      const input = this.table.search;
      if (!input || input.length == 0)
        this.table.data = this.table.filteredData;
      else {
        this.table.data = this.table.filteredData.filter((record) => {
          const results = Object.keys(record).filter((key) => {
            const value = record[key];
            if (value) {
              if (typeof value == "number") {
                return value == input;
              } else if (typeof value == "string")
                return value.toLowerCase().includes(input.toLowerCase());
            }
          });
          if (results.length > 0) return record;
        });
      }
    },
    selectItemsClicked: function (data, item) {
      const { startOffset, endOffset } = window.getSelection().getRangeAt(0);
      if (startOffset !== endOffset) return;
      const visibleItems = this.table.filteredItemsByVuetify;
      if (this.table.multiRowSelection) {
        const last_idx = visibleItems.findIndex(
          (item) =>
            item.id == this.table.selected[this.table.selected.length - 1].id
        );
        const item_idx = item.index;
        if (last_idx == item_idx) return;
        const min_idx = Math.min(item_idx, last_idx);
        const max_idx = Math.max(item_idx, last_idx);
        this.table.filteredItemsByVuetify
          .slice(min_idx, max_idx)
          .forEach((item) => {
            item.isSelected = true;
            const itemExists = this.table.selected.some(
              ({ id }) => id == item.id
            );
            if (!itemExists && item.isSelected) this.table.selected.push(item);
          });
        this.table.multiRowSelection = false;
      }
      item.isSelected = !item.isSelected;
      if (item.isSelected) this.table.selected.push(data);
      else
        this.table.selected = this.table.selected.filter(
          (selected) => selected.id != data.id
        );
      this.table.selected = [...new Set(this.table.selected)];
    },
    toggleTabletMode: function () {
      if (this.table.tabletMode)
        this.table.headers = this.reorderedTableHeaders.map((header) => {
          header.visible = true;
          return header;
        });
      else this.hideExtraColumns();
    },
    hideExtraColumns: function () {
      let tableWidth = this.table.dom.offsetWidth;
      const containerWidth = this.$refs.animalsTable.$el.offsetWidth;
      while (tableWidth > containerWidth) {
        const lastColumn = this.table.headers[this.table.headers.length - 1];
        lastColumn.visible = false;
        this.table.hiddenColumns.push(lastColumn);
        this.table.headers = this.table.headers.filter(
          ({ visible }) => !!visible
        );
        tableWidth =
          this.table.headers.reduce((previous, current) => {
            previous += document.getElementsByClassName(current.class)[0]
              .offsetWidth;
            return previous;
          }, 0) + 56;
      }
    },
    isVisible: function (element) {
      if (
        element.offsetWidth ||
        element.offsetHeight ||
        element.getClientRects().length
      )
        return true;

      return false;
    },
    getColumnsElements: function () {
      const rows = this.table.dom.rows;
      const col_idxs = [...rows[0].children].map((_, col_idx) => col_idx);
      const columns = col_idxs.map((col_idx) =>
        [...rows].map(({ children }) => children[col_idx])
      );
      return columns;
    },
    resetSearchInputs: async function () {
      this.table.columnSearches = await this.getSearchableHeaders();
      this.table.search = "";
      this.resetSearchDialog.show = false;
      this.table.data = this.table.filteredData;

      this.resetRowsPerPage();
    },
    createGeopointsMap: async function () {
      this.mapDialog.show = true;
      this.mapDialog.loading = true;
      const records = this.table.selected;
      this.mapDialog.geopointsCollection =
        await this.herdMeta.getLatestGeopointsForSelection(records);
      if (this.mapDialog.geopointsCollection.length == 0) {
        this.mapDialog.loading = false;
        return;
      }

      await this.initGoogleMapInstance();
      if (this.googleMapIsNotAvailable) {
        this.mapDialog.loading = false;
        return;
      }
      this.mapDialog.loading = false;
      this.createMapFromGeopoints(this.mapDialog.geopointsCollection);
    },
    initGoogleMapInstance: async function () {
      if (!window.google) {
        const googleMapApi = new Loader({
          apiKey: "AIzaSyDTFTTJGbg02sid2CsYocV11bSFvEY5hDg",
        });
        try {
          await googleMapApi.load();
          this.$google = window.google;
        } catch (error) {
          this.googleMapIsNotAvailable = true;
          return;
        }
      }
      this.googleMapIsNotAvailable = false;
    },
    createMapFromGeopoints: function (geopointsCollection) {
      this.$nextTick(() => {
        const mapContainer = this.$refs.googleMap;
        const mainMapOption = {
          center: {
            lat: geopointsCollection[0].lat,
            lng: geopointsCollection[0].lng,
          },
          zoom: 5,
        };
        const map = new google.maps.Map(mapContainer, mainMapOption);
        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        });
        const markers = geopointsCollection.map((location) => {
          const amount = geopointsCollection
            .filter(
              ({ lat, lng }) => lat == location.lat && lng == location.lng
            )
            .length.toString();
          const icon = {
            origin: new google.maps.Point(0, -10),
            scaledSize: new google.maps.Size(80, 50),
            url: `http://maps.google.com/mapfiles/ms/icons/red.png`,
          };
          const marker = new google.maps.Marker({
            icon,
            label: { text: amount, color: "black" },
            position: location,
          });
          marker.addListener("click", () => {
            infoWindow.setContent(
              `<h5 class="ma-0" style="color:black">${location.lat}, ${location.lng}</h5>`
            );
            infoWindow.open(map, marker);
          });
          return marker;
        });
        new MarkerClusterer({
          markers,
          map,
        });
      });
    },
    getFilteredItems: function (items) {
      this.table.filteredItemsByVuetify = items;
    },
    moveColumn: function () {
      const column = this.inputs.moveColumn;
      if (!column || column.length == 0) return;
      this.makeHiddenColumnVisible(column);
      this.putColumnFirstOfAll(column);
      this.putColumnFirstOfAll("rowNumber");
      if (!this.table.tabletMode)
        this.$nextTick(() => {
          this.hideExtraColumns();
        });

      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "columns"),
        JSON.stringify(this.reorderedTableHeaders)
      );
    },
    putColumnFirstOfAll: function (column) {
      this.reorderedTableHeaders = (
        this.prevTableHeaders || this.tableHeaders
      ).sort((x, y) => (x.value == column ? -1 : y.value == column ? 1 : 0));
      this.table.headers.sort((x, y) =>
        x.value == column ? -1 : y.value == column ? 1 : 0
      );
    },
    makeHiddenColumnVisible: function (column) {
      if (!this.table.headers.some(({ value }) => column == value)) {
        const col = (this.prevTableHeaders || this.tableHeaders).find(
          ({ value }) => column == value
        );
        col.visible = true;
        this.table.hiddenColumns = this.table.hiddenColumns.filter(
          ({ value }) => value != col.value
        );
        this.table.headers.push(col);
      }
    },
    filterData: async function () {
      let filteredData = await this.getAnimals();
      const selectedGroupNumbers = this.inputs.selectedGroupNumbers;
      const selectedHerds = this.inputs.selectedHerds;
      const selectedStatus = this.inputs.selectedStatus.split(",");

      if (selectedGroupNumbers && selectedGroupNumbers.length > 0) {
        filteredData = filteredData.filter(({ groupNumber }) =>
          selectedGroupNumbers.includes(groupNumber)
        );
      }
      if (selectedHerds && selectedHerds.length > 0) {
        filteredData = filteredData.filter(({ herd }) =>
          selectedHerds.includes(herd)
        );
      }

      if (selectedStatus && selectedStatus.length > 0) {
        const statusIncludeNot = selectedStatus.some((status) =>
          status.includes("Not")
        );
        if (statusIncludeNot) {
          const statuses = selectedStatus.map((status) =>
            status.substring(3).toLowerCase()
          );
          filteredData = filteredData.filter(
            ({ status }) => !statuses.includes((status || "").toLowerCase())
          );
        } else {
          const statuses = selectedStatus.map((s) => s.toLowerCase());
          if (statuses[0] != "all")
            filteredData = filteredData.filter(({ status }) =>
              statuses.includes((status || "").toLowerCase())
            );
        }
      }
      this.table.filteredData = filteredData;
      this.table.data = filteredData;
    },
    getSearchableHeaders: function () {
      return new Promise((resolve) => {
        const obj = {};
        (this.prevTableHeaders || this.tableHeaders).forEach(
          ({ value, searchable }) => {
            if (searchable) obj[value] = "";
          }
        );

        resolve(obj);
      });
    },
    toggleColumnsVisibility: function () {
      this.table.loading = true;
      this.table.headers = this.reorderedTableHeaders.filter(
        ({ visible, value }) => {
          if (!visible) this.table.columnSearches[value] = "";
          return !!visible;
        }
      );

      localStorage.setItem(
        this.getLayoutSettingName(this.layouts.current, "columns"),
        JSON.stringify(this.reorderedTableHeaders)
      );
      this.table.loading = false;
    },
    prepareFilters: async function () {
      const groupNumbers = await this.pouches.organization.query(
        "local_views/groupNumbers",
        {
          group_level: 1,
        }
      );

      if (groupNumbers.rows instanceof Array)
        this.options.groupNumbers = [
          ...new Set(groupNumbers.rows.map(({ key }) => key)),
        ];

      const herdOptions = await this.herdMeta.getMetaHerdsAsync(
        true,
        true,
        true
      );
      if (herdOptions instanceof Array)
        this.options.herds = [...new Set(herdOptions.map(({ name }) => name))];
    },
    prepareEditors: async function () {
      this.selectiveDeleteDialog.form.dateType = "createdOn";
      this.selectiveDeleteDialog.form.type =
        this.selectiveDeleteDialog.optionsTypes[0].key;
      this.tagDialog.tagsAvailable = await this.herdMeta.getMetaTagTableAsync(
        true,
        true,
        true
      );

      this.tagDialog.tagsAvailable.forEach((tagAvailable) => {
        tagAvailable.label =
          tagAvailable.name +
          ` (${this.getLabelTranslation(
            `enums.tagTypes.${tagAvailable.type.toLowerCase()}`,
            tagAvailable.type
          )})`;
      });

      this.tagDialog.typesAvailable = this.getEnumOptions("tagTypes");

      this.colorDialog.colorsAvailable = await this.herdMeta.getMetaColorsAsync(
        true,
        true,
        true
      );
      this.colorDialog.colorsAvailable = this.colorDialog.colorsAvailable.map(
        ({ color, id }) => ({ label: color, value: id })
      );

      const herdsAvailable = await this.herdMeta.getMetaHerdsAsync(
        true,
        true,
        true
      );
      this.herdDialog.herdsAvailable = [
        { id: "", name: "" },
        ...herdsAvailable,
      ];

      this.locationDialog.locationsAvailable = this.moveDialog.locationItems =
        await this.herdMeta.getMetaLocationsAsync(true, true, true);

      const origins = await this.herdMeta.getMetaOriginsAsync(true, true, true);
      this.originDialog.originsAvailable = origins.map(({ origin, id }) => ({
        label: origin,
        value: id,
      }));

      this.vitalsDialog.originsAvailable = this.originDialog.originsAvailable;

      const sexes = await this.herdMeta.getMetaSexesAsync(true, true, true);
      this.sexDialog.sexesAvailable = sexes.map((sex) => ({
        ...sex,
        label: sex.sex,
        value: sex.id,
      }));

      this.statusDialog.statusesAvailable = this.getEnumOptions("statuses");

      this.vitalsDialog.breedsAvailable = this.getEnumOptions("breeds");
      this.vitalsDialog.calvingScoresAvailable =
        this.getEnumOptions("birthCalvingEases");
      this.vitalsDialog.gendersAvailable = this.getEnumOptions("genders");

      this.vitalsDialog.speciesAvailable = this.getEnumOptions("species");
      this.vitalsDialog.vigorsAvailable = this.getEnumOptions("vigors");

      this.activateNewestTagsDialog.activateableTagTypes = this.getEnumOptions(
        "activateableTagTypes"
      );

      this.deactivateOldestTagsDialog.tagTypes = this.getEnumOptions(
        "activateableTagTypes"
      );
    },
    filterEidsAndVisuals: async function () {
      const rows = this.table.selected;
      const map_guids_to_ignore = rows
        .map(({ guid }) => guid)
        .reduce((reduction, guid) => {
          reduction[guid] = true;
          return reduction;
        }, {});

      const options =
        await this.herdMeta.pouches.organization.tagToAnimalShortSummaryBeyondThresholdAndMatchingStatuses(
          null,
          null,
          map_guids_to_ignore,
          null,
          false,
          null
        );

      this.parentDialog.search.items.eids = Object.keys(options.eid).sort();
      this.parentDialog.search.items.eidToAnimalShortSummaries = options.eid;
      this.parentDialog.search.items.visuals = Object.keys(
        options.visual
      ).sort();
      this.parentDialog.search.items.visualToAnimalShortSummaries =
        options.visual;
    },
    moveAnimalsLocation: function () {
      const d = $.Deferred();

      let timeRecorded = this.moveDialog.form.date
        ? moment(this.moveDialog.form.date).toISOString()
        : new Date().toISOString();
      if (
        moment(timeRecorded).format("YYYY-DD-MM") ===
        moment().format("YYYY-DD-MM")
      ) {
        timeRecorded = new Date().toISOString();
      }
      if (moment().diff(timeRecorded, "milliseconds") < 0) {
        this.notifyError(
          this.getLabelTranslation("generics.datePresentOrPastOnly")
        );
        return d.reject();
      }

      const data = this.table.selected;
      const promises = data.map((values) => {
        const d = $.Deferred();

        const locationId = this.moveDialog.form.location.id;
        const location = this.moveDialog.form.location.name;
        const reason = $.trim(this.moveDialog.form.reason);
        const status = $.trim(this.moveDialog.form.status);

        this.pouches.organization
          .get(values.id)
          .then((doc) => {
            const subPromises = [];

            const userId = this.$userID;
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            if (locationId)
              subPromises.push(
                animal.modify(
                  "movements",
                  null,
                  "locationId",
                  locationId,
                  false,
                  true,
                  {
                    location,
                    reason,
                    timeRecorded,
                    userId,
                  }
                )
              );
            if (reason)
              subPromises.push(
                animal.modify("comments", null, "comment", reason, true, {
                  timeRecorded,
                  userId,
                })
              );
            if (status) {
              subPromises.push(
                animal.modify("statuses", null, "status", status, false, true, {
                  timeRecorded,
                  userId,
                })
              );
            }

            $.when.apply($, subPromises).then(() => {
              animal
                .save()
                .fail(console.error)
                .always(() => {
                  d.resolve();
                });
            });
          })
          .catch((e) => {
            console.error(e);
            d.reject();
          });

        d.done(() => {});

        return d.promise();
      });
      $.when.apply($, promises).then(async () => {
        this.moveDialog.show = false;
        this.notifySuccess("Saved");
        await this.filterData();
        d.resolve();
      });
      this.table.selected = [];
      return d.promise();
    },
    forceUpdate: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );

            // force update
            return animal.modify(
              "comments",
              null,
              "comment",
              "Force updated",
              false,
              false,
              {
                deleted: true,
              }
            );
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.forceUpdateDialog.show = false;
          this.notifySuccess("Saved");
        })
        .catch((e) => {
          this.notifyError("Error updating");
          console.error(e);
          return;
        });

      this.table.selected = [];
    },
    runSelectiveDelete: function () {
      const rows = this.table.selected;
      const type = this.selectiveDeleteDialog.form.type;
      const timeRecordedStartISO = moment(this.selectiveDeleteDialog.form.date)
        .startOf("day")
        .toISOString();
      const timeRecordedEndISO = moment(this.selectiveDeleteDialog.form.date)
        .endOf("day")
        .toISOString();

      const dateType = this.selectiveDeleteDialog.form.dateType;
      const compareWithCreatedOn = dateType == "createdOn";

      const groupNumber = (
        this.selectiveDeleteDialog.form.groupNumber || ""
      ).trim();
      const promises = rows.map(async ({ id }) => {
        const doc = await this.pouches.organization.get(id);
        const animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);

        let dataFound = false;
        switch (type) {
          case "geopoints":
          case "movements":
          case "dnaNumbers":
          case "herds":
          case "weights":
            (animal.doc[type] || [])
              .filter(
                ({ createdOn, deleted, timeRecorded }) =>
                  !deleted &&
                  ((compareWithCreatedOn &&
                    createdOn &&
                    timeRecordedStartISO.localeCompare(createdOn) <= 0 &&
                    timeRecordedEndISO.localeCompare(createdOn) >= 0) ||
                    (!compareWithCreatedOn &&
                      timeRecorded &&
                      timeRecordedStartISO.localeCompare(timeRecorded) <= 0 &&
                      timeRecordedEndISO.localeCompare(timeRecorded) >= 0))
              )
              .forEach((record) => {
                dataFound = true;
                record.deleted = true;
              });
            break;
          case "groupNumber":
            if (groupNumber === "") return;

            (animal.doc.movements || [])
              .filter(
                (record) =>
                  !record.deleted &&
                  record.groupNumber == groupNumber &&
                  ((compareWithCreatedOn &&
                    record.createdOn &&
                    timeRecordedStartISO.localeCompare(record.createdOn) <= 0 &&
                    timeRecordedEndISO.localeCompare(record.createdOn) >= 0) ||
                    (!compareWithCreatedOn &&
                      record.timeRecorded &&
                      timeRecordedStartISO.localeCompare(record.timeRecorded) <=
                        0 &&
                      timeRecordedEndISO.localeCompare(record.timeRecorded) >=
                        0))
              )
              .forEach((record) => {
                dataFound = true;
                record.deleted = true;
              });
          case "eid":
          case "visual":
            (animal.doc.ids || [])
              .filter(
                (record) =>
                  !record.deleted &&
                  record.type == type &&
                  ((compareWithCreatedOn &&
                    record.createdOn &&
                    timeRecordedStartISO.localeCompare(record.createdOn) <= 0 &&
                    timeRecordedEndISO.localeCompare(record.createdOn) >= 0) ||
                    (!compareWithCreatedOn &&
                      record.timeRecorded &&
                      timeRecordedStartISO.localeCompare(record.timeRecorded) <=
                        0 &&
                      timeRecordedEndISO.localeCompare(record.timeRecorded) >=
                        0))
              )
              .forEach((record) => {
                dataFound = true;
                record.status = "inactive";
              });
            break;
          default:
            throw "Type is not supported";
        }

        if (!dataFound) return;
        return animal.summarizeAndCalculatDerivedDataAndSummaryAsync(
          true,
          false
        );
      });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.selectiveDeleteDialog.show = false;
          this.notifySuccess();
        })
        .catch((e) => {
          this.notifyError("An error ocurred" + e);
          console.error(e);
          return;
        });

      this.table.selected = [];
    },
    deleteRows: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            return animal.deleteAnimal();
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.deleteDialog.show = false;
          this.notifySuccess("Rows deleted");
        })
        .catch((e) => {
          this.notifyError("Error deleting rows");
          console.error(e);
          return;
        });
      this.table.selected = [];
    },
    activateNewestTags: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            const whichTagTypesToActivate =
              this.activateNewestTagsDialog.whichTags;
            return animal.activateNewestTags(
              whichTagTypesToActivate === "all" ||
                whichTagTypesToActivate === "BacktagOnly",
              whichTagTypesToActivate === "all" ||
                whichTagTypesToActivate === "EIDonly",
              whichTagTypesToActivate === "all" ||
                whichTagTypesToActivate === "VIDonly",
              false,
              false
            );
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });
      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.activateNewestTagsDialog.show = false;
          this.notifySuccess("Newest Tags activated");
        })
        .catch((e) => {
          this.notifyError("Error activating newest tags");
          console.error(e);
          return;
        });
      this.table.selected = [];
    },
    deactivateOldestTags: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            const whichTagTypesToActivate =
              this.activateNewestTagsDialog.whichTags;
            return animal.deactivateOldestTags(
              whichTagTypesToActivate === "all" ||
                whichTagTypesToActivate === "BacktagOnly",
              whichTagTypesToActivate === "all" ||
                whichTagTypesToActivate === "EIDonly",
              whichTagTypesToActivate === "all" ||
                whichTagTypesToActivate === "VIDonly",
              false,
              false
            );
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });
      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.deactivateOldestTagsDialog.show = false;
          this.notifySuccess("Deactivate Oldest Tags");
        })
        .catch((e) => {
          this.notifyError("Error deactivating oldest tags");
          console.error(e);
          return;
        });
      this.table.selected = [];
    },
    editComment: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            return animal
              .modify(
                "comments",
                null,
                "comment",
                this.commentDialog.comment,
                false
              )
              .fail(console.error);
          })
          .catch((e) => {
            console.error(e);
          });
      });
      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.commentDialog.show = false;
          this.commentDialog.comment = null;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch(() => {
          this.notifyError("Error Updating Comment");
          return;
        });
      this.table.selected = [];
    },
    editTag: async function () {
      const { id } = this.table.selected[0];
      const value = {
        tagValue: this.tagDialog.tagValue,
        type: this.tagDialog.type,
        tagId: this.tagDialog.tagTable,
      };
      const promises = await this.pouches.organization
        .get(id)
        .then((doc) => {
          const animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
          return value.tagValue
            .split(",")
            .map((tagValue) => tagValue.trim())
            .filter((tagValue) => !!tagValue)
            .map((tagValue) => {
              return {
                status: "active",
                tagId: value.tagId,
                tagValue: tagValue,
                type: value.type,
              };
            })
            .map(async (id) => {
              animal.insertIDforAnimal(id, true, false, true);
              return animal.save();
            });
        })
        .catch((e) => {
          this.notifyError();
          console.error(e);
          return;
        });
      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.tagDialog.show = false;
          this.tagDialog.tagValue = null;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError("Error Updating Tag");
          console.error(e);
          return;
        });
      this.table.selected = [];
    },
    editBredTo: function () {
      if (
        this.table.selected.filter(
          (item) => item.gender && item.gender.toLowerCase().includes("female")
        ).length === 0
      ) {
        this.notifyError("There are no females selected");
        return;
      }
      const promises = this.table.selected
        .filter(
          (item) => item.gender && item.gender.toLowerCase().includes("female")
        )
        .map(({ id }) => {
          return this.pouches.organization
            .get(id)
            .then(async (doc) => {
              const animal = new Animal(
                doc._id,
                this.herdMeta,
                doc,
                this.$userID
              );
              return animal.modify(
                "fertilizations",
                null,
                null,
                null,
                false,
                false,
                {
                  createdOn: new Date().toISOString(),
                  sireId: this.bredToDialog.sire.guid,
                  sireTags:
                    this.bredToDialog.sire.doc.derived.summaries.main.tagValues,
                  status: "Bred",
                  statusId: "implanted",
                  timeRecorded: moment(this.bredToDialog.date).toISOString(),
                  type: "ai",
                  userId: this.$userID,
                }
              );
            })
            .catch((e) => {
              this.notifyError();
              console.log(e);
              return;
            });
        });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.bredToDialog.show = false;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError("Error updating");
          console.log(e);
          return;
        });

      this.table.selected = [];
    },
    editColor: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );

            return animal.modify(
              "colors",
              null,
              "colorId",
              this.colorDialog.color.value,
              false,
              false,
              {
                color: this.colorDialog.color.label,
              }
            );
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.colorDialog.show = false;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError("Error updating");
          console.error(e);
          return;
        });

      this.table.selected = [];
    },
    editDaysBred: function () {
      const daysBred = this.daysBredDialog.daysBred;
      this.daysBredDialog.daysBred = null;

      const promises = this.table.selected.map((item) =>
        this.pouches.organization
          .get(item.id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );

            return animal.updateLastPregCheck("daysBred", daysBred, item);
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          })
      );

      Promise.all(promises)
        .catch((e) => {
          this.notifyError("Error updating");
          console.error(e);
          return;
        })
        .then(async () => {
          await this.filterData();
          this.daysBredDialog.show = false;
          this.notifySuccess("Saved");
        });

      this.table.selected = [];
    },
    editGroupNumber: function () {
      const promises = this.table.selected.map((item) => {
        return this.pouches.organization
          .get(item.id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            return animal.modify(
              "movements",
              null,
              "groupNumber",
              this.groupDialog.groupNumber,
              false,
              false,
              {
                locationId: item.locationId,
              }
            );
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });
      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.groupDialog.show = false;
          this.groupDialog.groupNumber = null;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError("Error updating");
          console.error(e);
          return;
        });

      this.table.selected = [];
    },
    editHerd: function () {
      let timeRecordedISO = moment(this.herdDialog.date).toISOString();
      const createdOn = new Date().toISOString();
      const dateIsToday =
        moment()
          .startOf("day")
          .diff(moment(timeRecordedISO).startOf("day"), "days") === 0;
      if (dateIsToday) {
        timeRecordedISO = createdOn;
      }
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );

            return animal.modify(
              "herds",
              null,
              "herdId",
              this.herdDialog.herd.id,
              false,
              false,
              {
                createdOn,
                herd: this.herdDialog.herd.name,
                timeRecorded: timeRecordedISO,
                userId: this.$userID,
              }
            );
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.herdDialog.show = false;
          this.herdDialog.herd = null;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError("Error updating");
          console.error(e);
          return;
        });

      this.table.selected = [];
    },
    editLocation: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );

            return animal.modify(
              "movements",
              null,
              "locationId",
              this.locationDialog.location.id,
              false,
              false,
              {
                location: this.locationDialog.location.name,
              }
            );
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.locationDialog.show = false;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError("Error updating");
          console.error(e);
          return;
        });
      this.table.selected = [];
    },
    editOrigin: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            return animal.modify(
              "origins",
              null,
              "originId",
              this.originDialog.origin.value,
              false,
              false,
              {
                origin: this.originDialog.origin.label,
              }
            );
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.originDialog.show = false;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError("Error updating");
          console.error(e);
          return;
        });
      this.table.selected = [];
    },
    getParentResult: function (animal) {
      if (
        animal.animal &&
        animal.animal.derived &&
        animal.animal.derived.summaries &&
        animal.animal.derived.summaries.main
      ) {
        this.parentDialog.resultSelected = animal.animal.derived.summaries.main;
        return;
      }
      this.parentDialog.resultSelected = null;
    },
    editParent: function () {
      const rows = this.table.selected;
      rows.map((row) => {
        this.pouches.organization
          .get(row.id)
          .then((doc) => {
            const child = new Animal(doc._id, this.herdMeta, doc, this.$userID);
            $.when(
              child.modify(
                this.parentDialog.type.value + "Ids",
                null,
                this.parentDialog.type.value + "Id",
                this.parentDialog.resultSelected.guid,
                false,
                true,
                {
                  parentTags: this.parentDialog.resultSelected.tagValues,
                  timeRecorded: new Date().toISOString(),
                }
              )
            ).then(() => {
              this.parentDialog.show = false;
              $.when(child.save())
                .fail((e) => {
                  this.notifyError(`Error saving animal, ${e}`);
                })
                .done(async () => {
                  await this.filterData();
                  this.notifySuccess(
                    `Animal Edited (<a href='/animal-details?id=${child.guid}' target="_blank">View</a>)`
                  );
                });
            });
          })
          .catch((e) => {
            this.notifyError(e);
            return;
          });
      });
      this.table.selected = [];
    },
    editRanch: function () {
      const groupNumber = this.ranchDialog.form.groupNumber.trim();
      const receivingRanch = this.ranchDialog.form.receivingRanch;
      let timeRecordedISO = moment(
        this.ranchDialog.form.moveDate
      ).toISOString();

      const dateIsToday =
        moment()
          .startOf("day")
          .diff(moment(timeRecordedISO).startOf("day"), "days") === 0;
      if (dateIsToday) {
        timeRecordedISO = new Date().toISOString();
        // Consider: Add geopoint data here
      }

      const promises = this.table.selected.map(({ id }) => {
        const d = $.Deferred();
        this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            const subPromises = [];

            subPromises.push(
              animal.modify(
                "movements",
                null,
                "locationId",
                null,
                false,
                true,
                {
                  groupNumber,
                  timeRecorded: timeRecordedISO,
                  userId: this.$userID,
                }
              )
            );
            subPromises.push(
              animal.modify(
                "receivingRanches",
                null,
                "receivingRanchId",
                receivingRanch.id,
                false,
                true,
                {
                  organizationId: receivingRanch.id,
                  receivingRanch: receivingRanch.name,
                  timeRecorded: timeRecordedISO,
                  userId: this.$userID,
                }
              )
            );

            $.when.apply($, subPromises).then(() => {
              $.when(animal.save()).fail(d.reject).done(d.resolve);
            });
          })
          .catch(d.reject);

        $.when(d).fail((e) => {
          this.notifyError("An error occurred." + e);
        });

        return d.promise();
      });

      $.when
        .apply($, promises)
        .done(async () => {
          await this.filterData();
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .always(() => {
          this.ranchDialog.show = false;
          this.resetRanchDialogFields();
        });
      this.table.selected = [];
    },
    editSex: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization.get(id).then((doc) => {
          const animal = new Animal(doc._id, this.herdMeta, doc, this.$userID);
          return [
            animal.modify(
              "sexes",
              null,
              "sexId",
              this.sexDialog.sex.value,
              false,
              true,
              {
                sex: this.sexDialog.sex.label,
              }
            ),
            animal.modify(
              "genders",
              null,
              "gender",
              this.sexDialog.sex && this.sexDialog.sex.gender
                ? this.vitalsDialog.gendersAvailable.find(
                    (g) => g.value === this.sexDialog.sex.gender
                  ).label
                : !!this.sexDialog.sex.female
                ? "Female"
                : "Male",
              false,
              false
            ),
          ];
        });
      });
      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.sexDialog.show = false;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError("Error updating");
          console.error("Error updating sex", e);
          return;
        });
      this.table.selected = [];
    },
    editStatus: function () {
      const date = moment(this.statusDialog.date).format("MM/DD/YYYY");
      let timeRecordedISO = moment(date, "MM/DD/YYYY").toISOString();
      const dateIsToday =
        moment()
          .startOf("day")
          .diff(moment(timeRecordedISO).startOf("day"), "days") === 0;
      if (dateIsToday) {
        timeRecordedISO = new Date().toISOString();
      }
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );

            const promises = [];
            if (this.statusDialog.comment) {
              promises.push(
                animal.modify(
                  "comments",
                  null,
                  "comment",
                  this.statusDialog.comment,
                  false,
                  true,
                  {
                    createdBy: this.$userID,
                    id: this.$utils.guid(),
                    timeRecorded: timeRecordedISO,
                    userId: this.$userID,
                  }
                )
              );
            }

            promises.push(
              animal.modify(
                "statuses",
                null,
                "status",
                this.statusDialog.status,
                false,
                false,
                {
                  timeRecorded: timeRecordedISO,
                  userId: this.$userID,
                }
              )
            );

            return new Promise(async (resolve, reject) => {
              $.when
                .apply($, promises)
                .done(() => {
                  resolve();
                })
                .fail((e) => {
                  reject(e);
                });
            });
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.statusDialog.show = false;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError(e);
          console.error(e);
          return;
        });
      this.table.selected = [];
    },
    editVitals: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );
            const subPromises = [];
            if (this.vitalsDialog.calvingScore)
              subPromises.push(
                animal.modify(
                  "birthCalvingEases",
                  null,
                  "birthCalvingEase",
                  this.vitalsDialog.calvingScore,
                  false,
                  true
                )
              );
            if (this.vitalsDialog.birthDate)
              subPromises.push(
                animal.modify(
                  "birthDates",
                  null,
                  "birthDate",
                  this.vitalsDialog.birthDate,
                  false,
                  true
                )
              );
            if (this.vitalsDialog.birthWeight)
              subPromises.push(
                animal.modify(
                  "birthWeights",
                  null,
                  "birthWeight",
                  this.vitalsDialog.birthWeight,
                  false,
                  true
                )
              );
            if (this.vitalsDialog.breed)
              subPromises.push(
                animal.modify(
                  "breeds",
                  null,
                  "breed",
                  this.vitalsDialog.breed,
                  false,
                  true
                )
              );
            if (this.vitalsDialog.deathDate)
              subPromises.push(
                animal.modify(
                  "deathDates",
                  null,
                  "deathDate",
                  this.vitalsDialog.deathDate,
                  false,
                  true
                )
              );
            if (this.vitalsDialog.gender)
              subPromises.push(
                animal.modify(
                  "genders",
                  null,
                  "gender",
                  this.vitalsDialog.gender,
                  false,
                  true
                )
              );
            if (this.vitalsDialog.origin)
              subPromises.push(
                animal.modify(
                  "origins",
                  null,
                  "originId",
                  this.vitalsDialog.origin.value,
                  false,
                  true,
                  {
                    origin: this.vitalsDialog.origin.label,
                  }
                )
              );
            if (this.vitalsDialog.percentCross)
              subPromises.push(
                animal.modify(
                  "percentCrosses",
                  null,
                  "percentCross",
                  this.vitalsDialog.percentCross,
                  false,
                  true
                )
              );
            if (this.vitalsDialog.specie)
              subPromises.push(
                animal.modify(
                  "species",
                  null,
                  "species",
                  this.vitalsDialog.specie,
                  false,
                  true
                )
              );
            if (this.vitalsDialog.vigor)
              subPromises.push(
                animal.modify(
                  "vigors",
                  null,
                  "vigor",
                  this.vitalsDialog.vigor,
                  false,
                  true
                )
              );
            return new Promise((resolve, reject) => {
              $.when.apply($, subPromises).then(() => {
                animal
                  .save()
                  .done(() => resolve())
                  .fail((e) => reject(e));
              });
            });
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });
      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.vitalsDialog.show = false;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError();
          console.error(e);
          return;
        });
      this.table.selected = [];
    },
    editRecordIncome: function () {
      const promises = this.table.selected.map(({ id }) => {
        return this.pouches.organization
          .get(id)
          .then((doc) => {
            const animal = new Animal(
              doc._id,
              this.herdMeta,
              doc,
              this.$userID
            );

            return animal.modify(
              "salesPurchases",
              null,
              "price",
              this.recordIncome.amount,
              false,
              false,
              {
                createdOn: new Date().toISOString(),
                eventOrganization: this.recordIncome.eventOrganization,
                transactionDate: this.recordIncome.transactionDate,
                transactionType: "income",
              }
            );
          })
          .catch((e) => {
            this.notifyError();
            console.error(e);
            return;
          });
      });

      Promise.all(promises)
        .then(async () => {
          await this.filterData();
          this.recordIncome.show = false;
          this.notifySuccess(this.getLabelTranslation("Saved"));
        })
        .catch((e) => {
          this.notifyError("Error updating");
          console.error(e);
          return;
        });
      this.table.selected = [];
    },
    runSearch: async function (type) {
      this.parentDialog.results = null;
      this.parentDialog.resultSelected = null;
      if (type === "eid" && !this.parentDialog.search.eid) return;
      if (type === "visual" && !this.parentDialog.search.visual) return;

      const guids =
        type === "eid"
          ? this.parentDialog.search.items.eidToAnimalShortSummaries[
              this.parentDialog.search.eid
            ]
          : this.parentDialog.search.items.visualToAnimalShortSummaries[
              this.parentDialog.search.visual
            ];

      this.parentDialog.results = (
        await this.herdMeta.pouches.organization.animalSummaries(false, false, {
          keys: guids.map(({ animalId }) => animalId),
        })
      ).rows.map(({ key, value }) => ({
        guid: key,
        tagValues: value.main.tagValues,
      }));
      if (this.parentDialog.results.length == 1)
        this.parentDialog.resultSelected = this.parentDialog.results[0];
      else this.parentDialog.resultSelected = null;
    },
    resetRanchDialogFields: function () {
      this.ranchDialog.form.groupNumber = null;
      this.ranchDialog.form.receivingRanch = null;
      this.ranchDialog.form.moveDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    },
    resetParentDialogFields: function () {
      this.parentDialog.results = [];
      this.parentDialog.resultSelected =
        this.parentDialog.search.eid =
        this.parentDialog.search.visual =
          null;
    },
    notifySuccess: function (message) {
      this.$notify({
        group: "forms",
        ignoreDuplicates: true,
        text: message || "Success",
        title: "Success",
        type: "success",
      });
    },
    notifyError: function (message) {
      this.$notify({
        group: "forms",
        ignoreDuplicates: true,
        text: message || "An error ocurred",
        title: "Error",
        type: "error",
      });
    },
    getAnimals: function () {
      return new Promise((resolve, reject) => {
        this.pouches.organization
          .animalSummaries(false, false)
          .then((results) => {
            const tableData = results.rows
              .map((row) => {
                // This was missing at least once, so we add it to be safe
                // Animals: Support animals that do not cache guid in summary structures
                // We do this right here instead of in animalSummaries to avoid forcing churn
                // on the animalSummaries view. This is probably the only page that depends on
                // the id being present in the summary
                // Add flex tag if exists
                const mostRecentFlexTag =
                  row.value.tags &&
                  row.value.tags.length > 0 &&
                  row.value.tags
                    .filter(({ type }) => type.toLowerCase() === "flex")
                    .sort((a, b) => {
                      if (a.lastUpdated && b.lastUpdated) {
                        return (
                          new Date(b.lastUpdated) - new Date(a.lastUpdated)
                        );
                      } else if (a.createdOn && b.createdOn) {
                        return new Date(b.createdOn) - new Date(a.createdOn);
                      } else if (a.timeRecorded && b.timeRecorded) {
                        return (
                          new Date(b.timeRecorded) - new Date(a.timeRecorded)
                        );
                      }
                      return 0;
                    })[0];
                row.value.main.flexTag = !!mostRecentFlexTag
                  ? mostRecentFlexTag.tagValue
                  : null;
                row.value.main.id = row.id;
                row.value.main.currentWeight =
                  row.value.weight[0].currentWeight;
                row.value.main.newTag =
                  row.value.tags &&
                  row.value.tags.length > 0 &&
                  row.value.tags[0].newTag;
                row.value.main.bullTest = row.value && row.value.bullCheck.test;
                row.value.main.bullTestMethod =
                  row.value && row.value.bullCheck.method;
                row.value.main.bullTestResult =
                  row.value && row.value.bullCheck.result;

                // Get last sighting: greater to lower

                const lastSighting = (row.value.sightingHistory || []).find(
                  (item) => item.type !== "geopoint" && !!item.locationId
                );
                // Get last movement: greater to lower
                const lastMovement = (row.value.movementsHistory || []).find(
                  (item) => item.type !== "geopoint" && !!item.locationId
                );
                const lastPasture =
                  !!lastSighting && !!lastMovement
                    ? moment(
                        lastSighting.timeRecorded || lastSighting.createdOn
                      ).isAfter(
                        moment(
                          lastMovement.timeRecorded || lastMovement.createdOn
                        )
                      )
                      ? lastSighting
                      : lastMovement
                    : lastSighting || lastMovement;

                row.value.main.location = lastPasture && lastPasture.location;
                row.value.main.locationId =
                  lastPasture && lastPasture.locationId;
                row.value.main.groupNumber =
                  row.value.groupNumbers &&
                  row.value.groupNumbers[0] &&
                  row.value.groupNumbers[0].groupNumber;

                // Get data that main does not have by default
                row.value.main.aiSireId =
                  row.value.fertilizations.ai.sireId || null;
                row.value.main.aiSireTags =
                  row.value.fertilizations.ai.sireTags || null;
                row.value.main.aiStatusId =
                  row.value.fertilizations.ai.statusId || null;
                row.value.main.bcs = row.value.pregCheck.bcs || null;
                row.value.main.cycle = row.value.pregCheck.cycle
                  ? this.getLabelTranslation(
                      `enums.cycles.${row.value.pregCheck.cycle}`,
                      this.getLabelTranslation(
                        `enums.cyclesExtensionFullText.${row.value.pregCheck.cycle}`,
                        row.value.pregCheck.cycle
                      )
                    )
                  : row.value.pregCheck.cycle || null;
                row.value.main.daysBred =
                  // convert to string so search functions
                  "" +
                  // Allow zeroes to come through
                  (row.value.pregCheck.daysBred === null ||
                  row.value.pregCheck.daysBred === undefined
                    ? ""
                    : row.value.pregCheck.daysBred);
                row.value.main.bullTurninDate =
                  row.value.pregCheck.bullTurninDate || null;
                row.value.main.fetalSex = row.value.pregCheck.fetalSex || null;
                row.value.main.hoof ||= null;
                row.value.main.milk ||= null;
                row.value.main.mouthScore =
                  row.value.pregCheck.mouthScore ||
                  (row.value.pregCheck.mouthScore &&
                    row.value.pregCheck.mouthScore.mouthScore)
                    ? row.value.pregCheck.mouthScore.mouthScore
                    : null;
                row.value.main.pelvicMeasure =
                  row.value.heiferCheck.pelvicMeasure || null;
                row.value.main.predictedCalvingDate =
                  row.value.pregCheck.predictedCalvingDate || null;
                row.value.main.pregCheckResult =
                  row.value.pregCheck.pregCheckResult ||
                  row.value.pregCheck.result ||
                  null;
                row.value.main.pregCheckStatus =
                  row.value.pregCheck.status || null;
                row.value.main.pregGroup =
                  row.value.pregCheck.groupNumber || null;
                row.value.main.pregMethod = row.value.pregCheck.method || null;
                row.value.main.pregTechnician =
                  row.value.pregCheck.technician || null;
                row.value.main.pregCheckTime = row.value.pregCheckHistory[0]
                  ? row.value.pregCheckHistory[0].pregCheckTime
                  : null;
                // Set purchase price from totalPurchasePrice in main summary
                // but if does not exist calculate total purchase price
                row.value.main.purchasePrice =
                  row.value.main.totalPurchasePrice ||
                  Animal.calculateTotalPurchasePrice(
                    row.value.purchaseSaleHistory
                  );

                // Set carcass data in row.value.main
                const lastCarcassData = (row.value.carcassData || [])[0];
                // Evaluate if lastCarcassData exists and set data if is ok
                // otherwise carcass data in row.value.main will be empty
                // x ||= y assign a value only if x is undefined or null
                if (lastCarcassData) {
                  row.value.main.carcassAge ||= lastCarcassData.carcassAge;
                  row.value.main.carcassType ||= lastCarcassData.carcassType;
                  row.value.main.carcId ||= lastCarcassData.carcId;
                  row.value.main.hotScaleWeight ||=
                    lastCarcassData.hotScaleWeight;
                  row.value.main.killDate ||= lastCarcassData.killDate;
                  row.value.main.killLot ||= lastCarcassData.killLot;
                  row.value.main.od ||= lastCarcassData.od;
                  row.value.main.od2 ||= lastCarcassData.od2;
                  row.value.main.plant ||= lastCarcassData.plant;
                  row.value.main.qualityGrade ||= lastCarcassData.qualityGrade;
                  row.value.main.qualityGrade2 ||=
                    lastCarcassData.qualityGrade2;
                  row.value.main.yieldGrade ||= lastCarcassData.yieldGrade;
                }

                return row.value.main;
              })
              .map((row, idx) => {
                // Append data that may be missing because older verions do not have it
                row.rowNumber = idx + 1;

                // Run some derivations
                row.age = Animal.getAgeOfTimestamp(row.birthDate, {
                  ignoreDays: true,
                  ignoreHours: true,
                });
                if (row.bullTurninDate)
                  row.bullTurninDate = moment(row.bullTurninDate).format(
                    "YYYY-MM-DD"
                  );
                row.clearanceTimeFrame = row.clearanceDate
                  ? moment
                      .duration(moment(row.clearanceDate).diff())
                      .humanize(true)
                  : "";
                row.daysSinceLastMove = Animal.computeDaysSinceLastMove(row);
                row.headDays = Animal.computeHeadDays(row);
                row.predictedCalvingDate = row.predictedCalvingDate
                  ? moment(row.predictedCalvingDate).format("YYYY-MM-DD")
                  : null;
                row.pregCheckTime = row.pregCheckTime
                  ? moment(row.pregCheckTime).format("YYYY-MM-DD HH:mm")
                  : null;
                return row;
              });

            resolve(tableData);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
</script>
<style scoped>
.hide {
  display: none;
}
.text-overflow-wrapped {
  white-space: initial;
  overflow: hidden;
}
.biggerFont {
  font-size: 1.7rem;
}
.mediumFont {
  font-size: 1.2rem;
}
.sticky-header {
  border-bottom: 0px !important;
  position: sticky;
  top: 92px;
  z-index: 2;
}
.reorder-sticky {
  border-bottom: 0px !important;
  position: sticky;
  top: 44px;
  z-index: 2;
}
</style>
<style>
.tablet-mode label {
  margin-bottom: 0px;
}
.selected-dark tbody tr.v-data-table__selected {
  background-color: #1565c0 !important;
}
.selected-light tbody tr.v-data-table__selected {
  background-color: #90caf9 !important;
}
#animalsTable td {
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}

#animalsTable .v-data-footer {
  order: 2;
}

#animalsTable:first-child {
  order: 1;
}

#animalsTable .v-data-table__wrapper {
  order: 3;
}

#animalsTable th {
  text-align: center !important;
}
</style>
